import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useUIStore } from "utils/UIStore";
//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import FunctionsIcon from "@mui/icons-material/Functions";
import WidgetsIcon from "@mui/icons-material/Widgets";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

// Firebase Imports
import Drawer from "@mui/material/Drawer";
import { useGetUserDetails } from "hooks/useGetUserDetails";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";
import Box from "@mui/system/Box/Box";

export default function AppDraw() {
  const navDrawOpen = useUIStore((state) => state.navDrawOpen);
  const setNavDrawOpen = useUIStore((state) => state.setNavDrawOpen);
  const user = useUIStore((state) => state.user);
  const [userData] = useGetUserDetails();

  const handleDrawerClose = () => {
    setNavDrawOpen(false);
  };

  return (
    <>
      {!!user && (
        <Drawer anchor="left" open={navDrawOpen}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Divider />
          <List>
            {[
              {
                label: "Search",
                icon: <SearchIcon />,
                href: "/search",
                show: userData && userData.membershipLevel >= 0,
              },
              {
                label: "QnA",
                icon: <QuestionAnswerIcon />,
                href: "/qna",
                show: userData && userData.membershipLevel >= 500,
              },
              {
                label: "Dashboards",
                icon: <DashboardIcon />,
                href: "/dashboards",
                show: true,
              },
              {
                label: "Notifications",
                icon: <NotificationsActiveIcon />,
                href: "/notifications",
                show: userData && userData.membershipLevel >= 100,
              },
              {
                label: "Metrics",
                icon: <FunctionsIcon />,
                href: "/metrics",
                show: userData && userData.membershipLevel >= 1_000,
              },

              {
                label: "Metric Admin",
                icon: <WidgetsIcon />,
                href: "/metricsAdmin",
                show: userData && userData.membershipLevel >= 1_000_000,
              },
              {
                label: "Data Loader Admin",
                icon: <AutorenewIcon />,
                href: "/dataLoaderAdmin",
                show: userData && userData.membershipLevel >= 1_000_000,
              },

              { label: "Settings", icon: <SettingsIcon />, href: "/settings" },
            ].map(
              ({ label, icon, href, show }, index) =>
                show && (
                  <ListItem
                    button
                    component={RouterLink}
                    to={href}
                    onClick={handleDrawerClose}
                  >
                    <ListItemText
                      primary={
                        <Box display={"flex"}>
                          {icon}
                          <Typography>{label}</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                )
            )}
          </List>
        </Drawer>
      )}
    </>
  );
}
