import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, updateDoc } from "@firebase/firestore";
import { firestore } from "utils/firebase";
import { Metric } from "types/Metric";
import { MetricTable } from "./MetricTable";
import Box from "@mui/material/Box/Box";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateMetricDialog } from "./CreateMetricDialog";
import { useUIStore } from "utils/UIStore";
import { MetricsAdminSettingsDialog } from "./MetricsAdminSettingsDialog";
import { doc } from "firebase/firestore";

export const MetricsAdminView = () => {
  const user = useUIStore((state) => state.user);
  const [metrics, setMetrics] = useState<Metric[]>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<Metric>();

  useEffect(() => {
    if (user) {
      const fetchMasterMetrics = async () => {
        console.log("Running", user.uid);
        const sharedWithQuery = query(collection(firestore, "metrics"));

        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const metrics = docs.map((d) => {
            return { id: d.id, ...d.data() } as Metric;
          });
          setMetrics(metrics);
        });
      };
      fetchMasterMetrics();
    }
  }, [user]);

  const onMetricSave = async (metric: Metric) => {
    console.log("Saving", metric);
    const docRef = doc(firestore, "metrics", metric.id);

    await updateDoc(docRef, metric as Record<string, any>);
    setSelectedMetric(undefined);
  };

  return (
    <>
      {!!user && !!metrics && (
        <>
          {addDialogOpen && (
            <CreateMetricDialog close={() => setAddDialogOpen(false)} />
          )}
          {selectedMetric && (
            <MetricsAdminSettingsDialog
              metric={selectedMetric}
              onSave={onMetricSave}
              onCancel={() => setSelectedMetric(undefined)}
            />
          )}
          <Box
            style={{ display: "flex", flexDirection: "column", padding: 10 }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <IconButton
                onClick={() => setAddDialogOpen(true)}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Box>
            <MetricTable
              metrics={metrics}
              onOpenSettings={(metric: Metric) => setSelectedMetric(metric)}
            ></MetricTable>
          </Box>
        </>
      )}
    </>
  );
};

export default MetricsAdminView;
