import { Box, IconButton } from "@mui/material";
import { query, collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "utils/firebase";
import { CreateDataLoaderDialog } from "./CreateDataLoaderDialog";
import { DataLoaderTable } from "./DataLoaderTable";
import { DataLoader } from "types/DataLoader";

// Icons

import AddIcon from "@mui/icons-material/Add";
import { useUIStore } from "utils/UIStore";

// export interface DataLoaderAdminProps {}

export const DataLoaderAdmin = () => {
  const user = useUIStore((state) => state.user);
  const [dataLoaders, setDataloaders] = useState<DataLoader[]>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchMasterMetrics = async () => {
        console.log("Running", user.uid);
        const sharedWithQuery = query(collection(firestore, "dataLoaders"));

        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dLS = docs.map((d) => {
            return { id: d.id, ...d.data() } as DataLoader;
          });
          setDataloaders(dLS);
        });
      };
      fetchMasterMetrics();
    }
  }, [user]);

  return (
    <>
      {!!user && !!dataLoaders && (
        <>
          {addDialogOpen && (
            <CreateDataLoaderDialog close={() => setAddDialogOpen(false)} />
          )}
          <Box
            style={{ display: "flex", flexDirection: "column", padding: 10 }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <IconButton
                onClick={() => setAddDialogOpen(true)}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Box>
            <DataLoaderTable dataLoaders={dataLoaders} />
          </Box>
        </>
      )}
    </>
  );
};
