import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Dashboard } from "types/Dashboard";
import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/system/Box/Box";
import { Link as RouterLink } from "react-router-dom";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";

export interface DashboardTableProps {
  rows: Dashboard[];
  showSettings?: boolean;
  showEdit?: boolean;
  onEditClickHandler?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function DashboardTable({
  rows,
  showEdit,
  showSettings,
  onEditClickHandler,
}: DashboardTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="right">Created At</TableCell>
            <TableCell align="right">Last Updated</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.title}
              </TableCell>
              <TableCell align="right">
                {row.createdAt.toDate().toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {row.lastEdited.toDate().toLocaleString()}
              </TableCell>
              <TableCell align="right">
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {showSettings && (
                    <Tooltip title="Settings">
                      <IconButton
                        onClick={() =>
                          onEditClickHandler && onEditClickHandler(row.id)
                        }
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {showEdit && (
                    <Tooltip title="Edit">
                      <RouterLink to={`/dashboards/edit/${row.id}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </RouterLink>
                    </Tooltip>
                  )}

                  <Tooltip title="View">
                    <RouterLink to={`/dashboards/${row.id}`}>
                      <IconButton>
                        <ArrowForwardIcon />
                      </IconButton>
                    </RouterLink>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
