import React from "react";
import { applyFilters, getValidFilters, useFactStore } from "../DataStore";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { groupyBySum } from "utils/dataManipulations";

export type StoreColumnChartProps = {
  id: string;
  metricID: number | string | null;
  yKey: string | null; // explicitly define the type as an array of strings
  color: string | null;
  xKey: string | null;
  dataLimit: number;
};

export const StoreColumnChart = ({
  id,
  metricID,
  yKey,
  color,
  xKey,
  dataLimit,
}: StoreColumnChartProps) => {
  const factSet = useFactStore((state) => state.factSet);
  const filters = useFactStore((state) => state.filters);
  if (!metricID || !xKey) {
    new Error("Missing Keys");
  }

  const data = applyFilters(
    getValidFilters(filters, id),
    factSet[metricID ?? ""]
  );

  const sorted = data.sort((a, b) =>
    +a[xKey ?? ""] > +b[xKey ?? ""] ? 1 : -1
  );

  const grouped = groupyBySum(sorted, xKey ?? "", yKey ?? "");

  const options: ApexOptions = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: grouped
        .slice(0, dataLimit)
        .map(
          (dataItem) => Math.round((dataItem[xKey ?? ""] as number) * 100) / 100
        ),
    },
    yaxis: {
      labels: {
        formatter: (value: number) => value.toFixed(2),
      },
    },
    dataLabels: {
      formatter: (value: number) => value.toFixed(2),
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 0,
    //       borderColor: "rgba(0,0,0,0)",
    //       borderWidth: 0,
    //       label: {
    //         borderColor: "rgba(0,0,0,0)",
    //         style: {
    //           color: "#000080",
    //           background: "rgba(0,0,0,0)",
    //           fontFamily: "monospace",
    //         },
    //         text: "Metric.Club",
    //       },
    //     },
    //   ],
    // },
    colors: [color],
  };

  const series = [
    {
      name: yKey ?? "",
      data: grouped.map((dataItem: any) => +dataItem[yKey ?? ""]), // use yKeys as an index to access the properties in dataItem
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};
