import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";

export const checkNotificationPermission = (): boolean => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
    return false;
  }

  return Notification.permission === "granted";
};

export function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:
          "BLFIGnjqTI7rpgqt886IaZSnnGRwhbHzO-acl28X9EOHABVfTnKeza03hpiMBRqiVjor9wbA4pGUVoTulkY211c",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log(currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  });
}

export const registerNotificationHandler = async () =>
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);

    const notificationTitle =
      payload?.notification?.title ?? "Notifiaction Title";
    const notificationOptions: NotificationOptions = {
      body: payload?.notification?.body,
      icon: payload?.notification?.image,
    };

    console.log(notificationOptions);

    new Notification(notificationTitle, notificationOptions);
    // ...
  });
