import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Metric } from "types/Metric";
import { firestore } from "utils/firebase";
import { MetricSelectionTable } from "./MetricSelectionTable";
import { useUIStore } from "utils/UIStore";

export interface EditMetricsDialogProps {
  currentMetrics: Set<string>;
  onClose: () => void;
  onUpdate: (metricIDs: Set<string>) => void;
}

export const EditMetricsDialog = ({
  onClose,
  onUpdate,
  currentMetrics,
}: EditMetricsDialogProps) => {
  const user = useUIStore((state) => state.user);
  const [metrics, setMetrics] = useState<Metric[]>();
  const [checkedIDs, setCheckedID] = useState<Set<string>>(currentMetrics);
  useEffect(() => {
    const fetchMetricMetaData = async () => {
      if (user) {
        console.log("Running");
        const metricsRef = collection(firestore, "metrics");

        // Create a query against the collection.
        const q = query(
          metricsRef,
          where("visibility", "==", "public"),
          where("status", "==", "valid")
        );

        onSnapshot(q, (querySnapshot) => {
          const metrics: Metric[] = [];
          querySnapshot.forEach((doc) => {
            metrics.push({ id: doc.id, ...doc.data() } as Metric);
          });
          setMetrics(metrics);
        });
      }
    };
    fetchMetricMetaData();
  }, [user]);

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <Typography variant="h5" textAlign="center">
        Select Metrics To Include
      </Typography>
      {metrics && (
        <Box style={{ padding: 10 }}>
          <MetricSelectionTable
            metrics={metrics}
            currentSet={checkedIDs}
            onSelect={setCheckedID}
          />
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={onClose}
          style={{ width: "10em" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          style={{ width: "10em" }}
          onClick={() => {
            onUpdate(checkedIDs);
            onClose();
          }}
        >
          Update
        </Button>
      </Box>
    </Dialog>
  );
};
