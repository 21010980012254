import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import React, { useEffect, useState } from "react";
import { Filter } from "types/Dashboard";
import { applyFilters, getValidFilters, useFactStore } from "../DataStore";

export type StoreBasicFilterProps = {
  id: string;
  filterID: string;
  metricID: string | number;
  valueKey: string | null;
  targets: string[];
  initialValue: string;
};

export const StoreBasicFilter = ({
  id,
  filterID,
  metricID,
  valueKey,
  targets,
  initialValue,
}: StoreBasicFilterProps) => {
  const factSet = useFactStore((state) => state.factSet);
  const filters = useFactStore((state) => state.filters);
  const setFilters = useFactStore((state) => state.setFilters);

  const data = applyFilters(getValidFilters(filters, id), factSet[metricID]);

  const options = data
    ? Array.from(new Set(data.map((x) => x[valueKey ?? ""]))).sort((a, b) =>
        a > b ? 1 : -1
      )
    : [];

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const otherFilters = filters.filter((x) => x.id !== filterID);

    const updatedFilter: Filter = {
      id: filterID,
      filterFn: (x) => {
        // eslint-disable-next-line eqeqeq
        return x[valueKey ?? ""] == value || value === "All";
      },
      targets: targets,
    };
    setFilters([...otherFilters, updatedFilter]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (e: SelectChangeEvent<any>) => {
    const otherFilters = filters.filter((x) => x.id !== filterID);

    const updatedFilter: Filter = {
      id: filterID,
      filterFn: (x) => x[valueKey ?? ""] === value,
      targets: targets,
    };
    setFilters([...otherFilters, updatedFilter]);
    setValue(e.target.value);
  };

  return (
    <>
      <InputLabel id={`${id}-filter-label`}>{valueKey}</InputLabel>
      <Select
        label={valueKey}
        labelId={`${id}-filter-label`}
        value={value}
        onChange={onChange}
        fullWidth
        style={{ paddingLeft: 2, marginRight: 10 }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
      >
        <MenuItem key={-1} value={"All"}>
          {"All"}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={`${option}`}>
            {`${option}`}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
