import React, { useEffect, useState } from "react";
import { firestore } from "utils/firebase";
import { useGetUserDetails } from "hooks/useGetUserDetails";
import { Box, Typography } from "@mui/material";
import Container from "@mui/system/Container/Container";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { ConversationItem } from "types/QnA";
import ConversationList from "./ConversationList";

export const QnAManager = () => {
  const [userData, loading] = useGetUserDetails();
  const [trainingDataLoading, setTrainingDataLoading] = useState<boolean>(true);
  const [trainingData, setTrainingData] = useState<ConversationItem[]>([]);

  const [schemaData, setSchemaData] = useState<any>([]);

  useEffect(() => {
    const fetch = async () => {
      const collectionRef = collection(firestore, "metrics");
      const baseMetricsQuery = query(
        collectionRef,
        where("tags", "array-contains-any", ["level0", "level1"])
      );

      onSnapshot(baseMetricsQuery, (querySnapshot) => {
        const baseMetrics = querySnapshot.docs;
        const schemaData = baseMetrics.map((metricDoc) => {
          const metric = metricDoc.data();
          return {
            tableName: `%%metric_${metric.metricID}%%`,
            tags: metric.tags,
            SCHEMA: metric.fields,
          };
        });

        setSchemaData(schemaData);
      });
    };
    fetch();
  });

  useEffect(() => {
    if (userData) {
      const fetchTrainingData = async () => {
        setTrainingDataLoading(true);
        const collectionRef = collection(firestore, "trainingData");

        onSnapshot(collectionRef, (querySnapshot) => {
          const trainingData = querySnapshot.docs.map((doc) => doc.data());
          setTrainingData(trainingData as ConversationItem[]);
          setTrainingDataLoading(false);
        });
      };
      fetchTrainingData();
    }
  }, [userData]);


  

  return (
    <Container>
      {!loading && userData && userData.membershipLevel < 1_000_000 && (
        <Typography> Computer Says No!</Typography>
      )}
      {!loading &&
        userData &&
        userData.membershipLevel >= 1_000_000 &&
        !trainingDataLoading &&
        trainingData && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <ConversationList conversation={trainingData} onEdit={(i) => {}} />
          </Box>
        )}{!loading &&
          userData &&
          userData.membershipLevel >= 1_000_000 &&
          !trainingDataLoading &&
          trainingData && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {schemaData.map((schema: any) => (
                <Typography>{JSON.stringify(schema)}</Typography>
              ))}
            </Box>
          )}
    </Container>
  );
};
