import React, { useState } from "react";
import { functions } from "utils/firebase";
import { httpsCallableFromURL } from "firebase/functions";
import { MetricTestInfo } from "types/Metric";
import { useGetUserDetails } from "hooks/useGetUserDetails";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/system/Container/Container";
import { TestDataTable } from "views/Metrics/TestDataTable";
import { QnAFeedback } from "./QnAFeedback";

const naturallangquery = httpsCallableFromURL(
  functions,
  "https://naturallangquery-fdqnt6qvaa-ts.a.run.app"
);

export const QnAView = () => {
  const [userData, loading] = useGetUserDetails();
  const [question, setQuestion] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [uuid, setUUID] = useState<string>();
  const [testData, setTestData] = useState<Record<string, unknown>[]>([]);
  const [testDataColumns, setTestDataColumns] = useState<MetricTestInfo[]>([]);
  const [queryLoading, setQueryLoading] = useState<boolean>(false);
  const [feedBackSent, setFeedBackSent] = useState<boolean>(false);

  const testView = async () => {
    setTestData([]);
    setTestDataColumns([]);
    setQueryLoading(true);
    setFeedBackSent(false);
    setUUID(undefined);
    let result: any;
    try {
      result = await (await naturallangquery({ question })).data;
      console.log(result);
    } catch (e) {
      alert(e);
      setQueryLoading(false);
    }
    if (result.data) {
      setTestData((result.data as any).rows as Record<string, unknown>[]);
      setTestDataColumns((result.data as any).rowInfo as MetricTestInfo[]);
      setUUID((result.data as any).uuid as string);
      setQuery(result.query);
    }
    setQueryLoading(false);
  };

  return (
    <Container style={{ paddingTop: 32 }}>
      {!loading && userData && userData.membershipLevel < 500 && (
        <Typography> Computer Says No!</Typography>
      )}
      {queryLoading && (
        <Dialog open={true}>
          <CircularProgress />
        </Dialog>
      )}
      {!loading && userData && userData.membershipLevel >= 500 && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Box
            height={150}
            style={{
              paddingTop: 32,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: 10,
            }}
          >
            <TextField
              label="Question"
              fullWidth
              rows={1}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: 10,
            }}
          >
            <Button variant="contained" onClick={testView}>
              Go!
            </Button>
            <Typography color="red" variant="caption" textAlign="center">
              Questions and responses are stored for training purposes.
            </Typography>
          </Box>
          {testData && testDataColumns && (
            <TestDataTable rows={testData} rowInfo={testDataColumns} />
          )}
          {testData && testDataColumns && uuid && (
            <>
              {!feedBackSent ? (
                <QnAFeedback
                  uuid={uuid}
                  onFeedbackSent={() => setFeedBackSent(true)}
                />
              ) : (
                <Typography textAlign={"center"}>
                  Thank you for your feedback.
                </Typography>
              )}
            </>
          )}
          {testData && testDataColumns && (
            <Typography variant="caption" style={{ paddingTop: 16 }}>
              Response: {query}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default QnAView;
