import React, { useEffect, useState } from "react";
import { firestore } from "utils/firebase";
import { useGetUserDetails } from "hooks/useGetUserDetails";
import { Box, Typography } from "@mui/material";
import Container from "@mui/system/Container/Container";
import { collection, onSnapshot } from "firebase/firestore";
import { ChatItem, Review, ReviewTable } from "./QnAFeedBackTable";

export const QnAFeedbackViewer = () => {
  const [userData, loading] = useGetUserDetails();
  const [feedbackDataLoading, setFeedbackDataLoading] = useState<boolean>(true);
  const [chatLogData, setChatLogData] = useState<ChatItem[]>([]);
  const [chatReviewData, setChatReviewData] = useState<Review[]>([]);

  useEffect(() => {
    if (userData) {
      const fetchchatLogData = async () => {
        setFeedbackDataLoading(true);
        const chatLogRef = collection(firestore, "chatLogs");
        const chatReviewRef = collection(firestore, "chatReviews");

        onSnapshot(chatLogRef, (querySnapshot) => {
          const chatLogData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            uuid: doc.id,
          }));
          setChatLogData(chatLogData as ChatItem[]);
          setFeedbackDataLoading(false);
        });
        onSnapshot(chatReviewRef, (querySnapshot) => {
          const chatReviewData = querySnapshot.docs.map((doc) => doc.data());
          setChatReviewData(chatReviewData as Review[]);
          setFeedbackDataLoading(false);
        });
      };
      fetchchatLogData();
    }
  }, [userData]);

  return (
    <Container>
      {!loading && userData && userData.membershipLevel < 1_000_000 && (
        <Typography> Computer Says No!</Typography>
      )}
      {!loading &&
        userData &&
        userData.membershipLevel >= 1_000_000 &&
        !feedbackDataLoading &&
        chatLogData && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <ReviewTable chatItems={chatLogData} reviews={chatReviewData} />
          </Box>
        )}
    </Container>
  );
};
