import React from "react";
import Box from "@mui/material/Box/Box";
import { Button, Card, Tooltip } from "@mui/material";

//Icons
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import BarChartIcon from "@mui/icons-material/BarChart";
import LineChartIcon from "@mui/icons-material/LineAxis";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import TableChartIcon from "@mui/icons-material/TableChart";

export interface VisualsBarProps {
  onSave: () => void;
  setMetricsOpen: () => void;
  canSave: boolean;
  canAdd: boolean;
}

export const VisualsBar = ({
  onSave,
  setMetricsOpen,
  canAdd,
  canSave,
}: VisualsBarProps) => {
  return (
    <Box
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        gap: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id="BarChart"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "BarChart")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Bar Chart">
            <SortIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <div
        id="TextArea"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "TextArea")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Text Area">
            <FontDownloadIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <div
        id="LineChart"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "LineChart")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Line Chart">
            <LineChartIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <div
        id="ColumnChart"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "ColumnChart")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Column Chart">
            <BarChartIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <div
        id="BasicFilter"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "BasicFilter")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Drop Down Filter">
            <FilterAltIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <div
        id="DataGrid"
        className="droppable-element"
        draggable={canAdd}
        unselectable="on"
        onDragStart={(e) => e.dataTransfer.setData("visualType", "DataGrid")}
      >
        <Card
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Drop Down Filter">
            <TableChartIcon color={canAdd ? "primary" : "disabled"} />
          </Tooltip>
        </Card>
      </div>
      <Button
        variant="contained"
        style={{ float: "right" }}
        onClick={setMetricsOpen}
      >
        Metrics
      </Button>
      <Button
        disabled={!canSave}
        variant="contained"
        style={{ float: "right" }}
        onClick={onSave}
      >
        Save
      </Button>
    </Box>
  );
};
