import React, { useState } from "react";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Dialog from "@mui/material/Dialog/Dialog";
import dayjs, { Dayjs } from "dayjs";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { firestore } from "utils/firebase";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DataLoader, LoaderType } from "types/DataLoader";

export interface CreateDataLoaderDialogProps {
  close: () => void;
}

export const CreateDataLoaderDialog = ({
  close,
}: CreateDataLoaderDialogProps) => {
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [collectionAddress, setCollectionAddress] = useState<string>();
  const [addressNickname, setAddressNickname] = useState<string>();
  const [initialCheckTime, setInitialCheckTime] = useState<Dayjs>();
  const [externalEndPoint, setExternalEndPoint] = useState<string>();
  const [internalEndPoint, setInternalEndPoint] = useState<string>();
  const [loaderType, setLoaderType] = useState<LoaderType>();

  const checkInterval = 60_000;

  const allPopulated =
    description &&
    title &&
    collectionAddress &&
    addressNickname &&
    initialCheckTime &&
    externalEndPoint &&
    internalEndPoint &&
    loaderType;

  const onCreate = async () => {
    if (allPopulated) {
      const collectionRef = collection(firestore, "dataLoaders");

      let inputs: DataLoader | undefined = undefined;

      if (loaderType === "BasicOrdersFilled") {
        inputs = {
          description,
          title,
          loaderType: "BasicOrdersFilled",
          active: true,
          status: "Initialization",
          visibility: "Hidden",
          loaderDetails: {
            address: collectionAddress,
            addressNickname,
            checkInterval,
            lastCheckedTime: new Timestamp(
              initialCheckTime.toDate().getTime() / 1_000,
              0
            ),
            internalEndPoint,
            externalEndPoint,
            pageSize: 2000,
          },
        };
      } else if (loaderType === "Metadata") {
        inputs = {
          description,
          title,
          loaderType: "Metadata",
          active: true,
          status: "Initialization",
          visibility: "Hidden",
          loaderDetails: {
            address: collectionAddress,
            addressNickname,
            checkInterval,
            lastCheckedTime: new Timestamp(
              initialCheckTime.toDate().getTime() / 1_000,
              0
            ),
            lastCompletedCycle: new Timestamp(
              initialCheckTime.toDate().getTime() / 1_000,
              0
            ),
            lastCheckedID: 0,
            internalEndPoint,
            externalEndPoint,
          },
        };
      }
      if (inputs) {
        await addDoc(collectionRef, inputs);
        close();
      }
    }
  };

  const onCollectionSet = async (collection: string) => {
    setCollectionAddress(collection);
    if (loaderType === "BasicOrdersFilled" && collection.length === 42) {
      setInternalEndPoint(
        "https://checkforlatestfilledorders-fdqnt6qvaa-ts.a.run.app"
      );
      setExternalEndPoint("https://api.x.immutable.com/v3/orders");

      const response = await fetch(
        `https://api.x.immutable.com/v1/collections/${collection}`
      );

      if (response.status === 200) {
        const data = await response.json();

        setAddressNickname(`${data.name}`);
        setTitle(`${data.name} Filled Orders`);
        const startTime = new Date(data.created_at);
        setInitialCheckTime(dayjs(startTime));
      }
    }
  };

  return (
    <Dialog open fullWidth maxWidth="md" style={{ minHeight: 400 }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          gap: 10,
        }}
      >
        <Typography variant="h5" textAlign="center">
          Create A Data Loader
        </Typography>
        <TextField
          value={title}
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          value={description}
          label="Description"
          multiline
          minRows={5}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Autocomplete
          disablePortal
          id="loader-type-dropdown"
          options={["BasicOrdersFilled", "Metadata"]}
          defaultValue={loaderType}
          renderInput={(params) => (
            <TextField {...params} label={"Loader Type"} />
          )}
          onChange={(e, v) => setLoaderType(v as LoaderType)}
        />
        <TextField
          value={collectionAddress}
          label="Collection Address"
          onChange={(e) => {
            onCollectionSet(e.target.value);
          }}
        />
        <TextField
          disabled={loaderType === "BasicOrdersFilled"}
          value={addressNickname}
          label={
            loaderType !== "BasicOrdersFilled"
              ? "Collection Nickname"
              : undefined
          }
          onChange={(e) => {
            setAddressNickname(e.target.value);
          }}
        />
        <TextField
          disabled={loaderType === "BasicOrdersFilled"}
          value={internalEndPoint}
          label={
            loaderType !== "BasicOrdersFilled"
              ? "Internal End Point"
              : undefined
          }
          onChange={async (e) => {
            setInternalEndPoint(e.target.value);
          }}
        />
        <TextField
          disabled={loaderType === "BasicOrdersFilled"}
          value={externalEndPoint}
          label={
            loaderType !== "BasicOrdersFilled"
              ? "External End Point"
              : undefined
          }
          onChange={(e) => {
            setExternalEndPoint(e.target.value);
          }}
        />
        <DateTimePicker
          disabled={loaderType === "BasicOrdersFilled"}
          label="Load Data Since"
          onChange={(newValue: Dayjs | null) =>
            setInitialCheckTime(newValue as any)
          }
        />
        <Box display={"flex"} flexDirection="row" justifyContent="space-evenly">
          <Button variant="contained" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onCreate}
            disabled={!allPopulated}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
