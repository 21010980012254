import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUIStore } from "utils/UIStore";

// Firebase Imports
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "utils/firebase";
import AppBar from "components/nav/AppBar";
import AppDraw from "components/nav/AppDraw";
export default function Root() {
  const user = useUIStore((state) => state.user);

  useEffect(() => {
    if (user && isSignInWithEmailLink(auth, window.location.href)) {
    }
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      const email = window.localStorage.getItem("emailForSignIn");
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email as string, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs
        });
    }
  }, [user]);

  return (
    <>
      <AppBar />
      <AppDraw />
      <Outlet />
    </>
  );
}
