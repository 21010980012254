import React from "react";
import Paper from "@mui/material/Paper/Paper";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import dayjs from "dayjs";

// Icons
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Types
import { DataLoader } from "types/DataLoader";
import { Typography } from "@mui/material";

export interface DataLoaderTableProps {
  dataLoaders: DataLoader[];
}

export const DataLoaderTable = ({ dataLoaders }: DataLoaderTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Loader ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Last Run</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataLoaders.map((row) => {
            const dayInfo = dayjs(row.loaderDetails.lastCheckedTime.toDate());
            return (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell key={1} component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.loaderType}</TableCell>
                <TableCell>
                  <Typography>{row.loaderDetails.address}</Typography>
                  <Typography>{row.loaderDetails.addressNickname}</Typography>
                </TableCell>
                <TableCell>{dayInfo.format("DD/MM/YYYY HH:mm")}</TableCell>
                <TableCell>
                  {row.active ? (
                    <PlayCircleOutlineIcon color="success" />
                  ) : (
                    <PauseIcon color="warning" />
                  )}
                  {row.status === "Okay" ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : row.status === "Initialization" ? (
                    <ErrorOutlineIcon color="warning" />
                  ) : (
                    <ErrorOutlineIcon color="error" />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
