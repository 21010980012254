export const groupyBySum = (
  data: any[],
  groupyByCol: string,
  sumCol: string
) => {
  const result = data.reduce(function (res, value) {
    if (!res[value[groupyByCol]]) {
      res[value[groupyByCol]] = {
        [groupyByCol]: value[groupyByCol],
        [sumCol]: 0,
      };
    }
    res[value[groupyByCol]][sumCol] += value[sumCol];
    return res;
  }, {});
  const resultsArray =  Object.keys(result).map((key) => result[key]);

  return resultsArray
};
