import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { firestore, functions } from "utils/firebase";
import {
  where,
  collectionGroup,
  query,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Dashboard as TDashboard,
  Visual,
  VisualType,
  VisualTextArea,
  VisualBasicFilter,
  VisualDataGrid,
} from "types/Dashboard";
import { useFactStore } from "components/Dashboard/DataStore";
import { VisualsBar } from "components/Dashboard/VisualsBar";
import { Layout } from "react-grid-layout";
import { EditVisualDialog } from "./EditVisualDialog";
import { Metric } from "types/Dashboard";
import { EditMetricsDialog } from "./EditMetricsDialog";
import { httpsCallableFromURL } from "firebase/functions";
import { MetricDashboard } from "../MetricDashboard";
import { useUIStore } from "utils/UIStore";

const getMetricByID = httpsCallableFromURL(
  functions,
  "https://querybyid-fdqnt6qvaa-ts.a.run.app"
);

export const DashboardEditor = () => {
  const user = useUIStore((state) => state.user);
  const location = useLocation();
  const [dashboardDetails, setDashboardDetails] = useState<TDashboard>();
  const setFactSet = useFactStore((x) => x.setFactSet);
  const [{ params }] = matchRoutes(
    [{ path: "dashboards/edit/:dashboardID" }],
    location
  ) ?? [{ params: {} }];
  const [liveLayout, setLiveLayout] = useState<Visual[]>([]);
  const [liveMetrics, setLiveMetrics] = useState<Metric[]>([]);
  const [editVisual, setEditVisual] = useState<Visual>();
  const [metricsDialogOpen, setMetricsDialogOpen] = useState(false);

  const dashboardID = params.dashboardID;
  useEffect(() => {
    const fetchDashboard = async () => {
      if (user && params && dashboardID) {
        const sharedWithQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("uid", "==", dashboardID),
          where("owner", "==", user.uid)
        );
        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: TDashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as TDashboard;
          });
          setDashboardDetails(dashboards[0]);
          setLiveLayout(dashboards[0].layout);
          setLiveMetrics(dashboards[0].metrics);
        });
      }
    };
    fetchDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dashboardID]);

  useEffect(() => {
    const fetchMetrics = async () => {
      if (liveMetrics) {
        const metrics = liveMetrics.map(async (m) => {
          const response = await getMetricByID({ id: m.id });
          const data = ((response as any).data as any).rows;
          return {
            [+m.id]: data,
          };
        });

        const resolved = await Promise.all(metrics);
        const metricsObject = resolved.reduce((a, b) => ({ ...a, ...b }), {});
        setFactSet(metricsObject);
      }
    };
    fetchMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMetrics]);

  const onSave = async () => {
    if (user && user.uid && dashboardID) {
      const docRef = doc(
        firestore,
        "users",
        user.uid,
        "dashboards",
        dashboardID
      );
      await updateDoc(docRef, "layout", liveLayout);
      await updateDoc(docRef, "metrics", liveMetrics);
    }
  };

  const onDrop = (layout: Layout[], layoutItem: Layout, _event: DragEvent) => {
    if (_event.dataTransfer) {
      const newVisualType = _event.dataTransfer.getData(
        "visualType"
      ) as VisualType;
      let newVisual: Visual | null = null;
      if ("TextArea" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id));
        newVisual = {
          id: `${maxID + 1}`,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            body: "New Text Element",
            variant: "body1",
            color: "black",
            textAlign: "left",
          },
        } as VisualTextArea;
      } else if ("BarChart" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id)) ?? 0;
        newVisual = {
          id: `${maxID + 1}`,
          metricID: undefined,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            xKey: null,
            yKey: null,
            color: "#FF0000",
            topN: 10,
          },
        };
      } else if ("LineChart" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id)) ?? 0;
        newVisual = {
          id: `${maxID + 1}`,
          metricID: undefined,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            xKey: null,
            yKey: null,
            color: "#FF0000",
          },
        };
      } else if ("ColumnChart" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id)) ?? 0;
        newVisual = {
          id: `${maxID + 1}`,
          metricID: undefined,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            xKey: null,
            yKey: null,
            color: "#FF0000",
          },
        };
      } else if ("BasicFilter" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id)) ?? 0;
        newVisual = {
          id: `${maxID + 1}`,
          metricID: undefined,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            filterID: `${maxID + 1}`,
            targets: [],
            valueKey: null,
            initialValue: "All",
          },
        } as VisualBasicFilter;
      } else if ("DataGrid" === newVisualType) {
        const maxID = Math.max(0, ...liveLayout.map((x) => +x.id)) ?? 0;
        newVisual = {
          id: `${maxID + 1}`,
          metricID: undefined,
          position: {
            x: layoutItem.x,
            y: layoutItem.y,
            h: layoutItem.h,
            w: layoutItem.w,
          },
          visualType: newVisualType,
          visualProperties: {
            columns: [],
            columnWidths: [],
          },
        } as VisualDataGrid;
      }
      if (newVisual) {
        setLiveLayout([...liveLayout, newVisual]);
      }
    }
  };

  const onResizeStop = (layout: Layout[]) => {
    const updatedLayout = liveLayout.map((v) => {
      const updatedLayout = layout.find((l) => l.i === v.id);
      return {
        ...v,
        position: {
          x: updatedLayout?.x ?? 0,
          y: updatedLayout?.y ?? 0,
          w: updatedLayout?.w ?? 1,
          h: updatedLayout?.h ?? 1,
        },
      };
    });
    setLiveLayout(updatedLayout);
  };

  return (
    <>
      {dashboardDetails && (
        <>
          <VisualsBar
            onSave={onSave}
            setMetricsOpen={() => setMetricsDialogOpen(true)}
            canAdd={liveMetrics.length > 0}
            canSave={true}
          />
          {editVisual && (
            <EditVisualDialog
              editVisual={editVisual}
              setEditVisual={setEditVisual}
              liveLayout={liveLayout}
              setLiveLayout={setLiveLayout}
              liveMetrics={liveMetrics}
            />
          )}
          {metricsDialogOpen && (
            <EditMetricsDialog
              onClose={() => setMetricsDialogOpen(false)}
              currentMetrics={new Set(liveMetrics.map((x) => x.id))}
              onUpdate={(metricSet) =>
                setLiveMetrics(Array.from(metricSet).map((id) => ({ id })))
              }
            />
          )}
          <MetricDashboard
            dashboardDetails={{ ...dashboardDetails, layout: liveLayout }}
            onDrop={onDrop}
            onResizeStop={onResizeStop}
            layoutOverride={liveLayout}
            setLayout={setLiveLayout}
            setEditVisual={setEditVisual}
            backgroundColor={"#F4F4F6"}
          />
        </>
      )}
    </>
  );
};

export default DashboardEditor;
