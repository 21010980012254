import { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Dialog,
} from "@mui/material";
import { Metric } from "types/Metric";
import { ArrayEditor } from "components/ArrayEditor";

interface MetricsAdminSettingsDialogProps {
  metric: Metric;
  onSave: (metric: Metric) => void;
  onCancel: () => void;
}

export const MetricsAdminSettingsDialog = ({
  metric,
  onSave,
  onCancel,
}: MetricsAdminSettingsDialogProps) => {
  const [editedMetric, setEditedMetric] = useState(metric);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedMetric((prevMetric) => ({ ...prevMetric, [name]: value }));
  };

  const handleTagsChange = (tags: string[]) => {
    setEditedMetric((prevMetric) => ({ ...prevMetric, tags }));
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setEditedMetric((prevMetric) => ({
      ...prevMetric,
      visibility: value as "Public" | "Hidden" | "Alpha",
    }));
  };

  const handleSave = () => {
    onSave(editedMetric);
  };

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5">Edit Metric</Typography>
        <FormControl>
          <InputLabel id="visibility-label">Visibility</InputLabel>
          <Select
            labelId="visibility-label"
            name="visibility"
            value={editedMetric.visibility}
            onChange={handleSelectChange as any}
          >
            <MenuItem value="public">public</MenuItem>
            <MenuItem value="hidden">hidden</MenuItem>
            <MenuItem value="alpha">alpha</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="title"
          label="Title"
          value={editedMetric.title}
          onChange={handleInputChange}
        />
        <TextField
          name="description"
          label="Description"
          value={editedMetric.description}
          onChange={handleInputChange}
        />
        <ArrayEditor
          arrayType="text"
          numbers={editedMetric.tags}
          onEdit={handleTagsChange}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
