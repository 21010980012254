import React from "react";
import { IconButton, useTheme } from "@mui/material";
import { Theme } from "@mui/system";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useUIStore } from "utils/UIStore";

export const ThemeToggleButton = () => {
  const [theme] = useUIStore((state) => [state.theme]);
  const [setTheme] = useUIStore((state) => [state.setTheme]);
  const muiTheme= useTheme<Theme>();

  const onToggle = () => {
    setTheme(theme === "light" ? "dark" : "light");
    muiTheme.palette.mode = theme === "light" ? "dark" : "light";
  };
  return (
    <IconButton onClick={onToggle}>
      {theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};
