import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { foundation as theme } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface Props {
  sql: string;
}

const SqlHighlighter = ({ sql }: Props) => (
  <SyntaxHighlighter language="sql" style={theme} showLineNumbers startingLineNumber={31} wrapLines wrapLongLines>
    {sql}
  </SyntaxHighlighter>
);

export default SqlHighlighter;
