import React from "react";
import Paper from "@mui/material/Paper/Paper";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import { Metric } from "types/Metric";
import { Checkbox, Typography } from "@mui/material";

export interface MetricSelectionTableProps {
  metrics: Metric[];
  currentSet: Set<string>;
  onSelect: (metricIDs: Set<string>) => void;
}

export const MetricSelectionTable = ({
  metrics,
  currentSet,
  onSelect,
}: MetricSelectionTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Metric ID</TableCell>
            <TableCell>Title</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics
            .sort((a, b) => (a.metricID > b.metricID ? 1 : -1))
            .map((row, i) => (
              <TableRow
                key={row.metricID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Checkbox
                    checked={currentSet.has(row.metricID)}
                    onChange={() => {
                      if (currentSet.has(row.metricID)) {
                        const updated = new Set(Array.from(currentSet));
                        updated.delete(row.metricID);
                        onSelect(updated);
                      } else {
                        const updated = new Set(Array.from(currentSet));
                        updated.add(row.metricID);
                        onSelect(updated);
                      }
                    }}
                  />
                </TableCell>
                <TableCell key={i} component="th" scope="row">
                  <Typography>{row.metricID}</Typography>
                </TableCell>
                <TableCell>{row.title}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
