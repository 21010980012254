import React from "react";

export const DemoDashboard = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginLeft: "74px",
        marginRight: "74px",
        marginTop: "74px",
      }}
    ></div>
  );
};

export default DemoDashboard;
