import { Filter } from "types/Dashboard";
import { create } from "zustand";

export type Fact = Record<string, number | string | Date>;

export type FactList = Fact[];

export type FactSet = Record<string, FactList>;

export type State = {
  factSet: FactSet;
  filters: Filter[];
};

export type Actions = {
  setFactSet: (factSet: FactSet) => void;
  setFilters: (filters: Filter[]) => void;
};

export type FactStore = State & Actions;

const initialFactStore: State = {
  factSet: {},
  filters: [],
};

export const useFactStore = create<FactStore>((set) => ({
  ...initialFactStore,
  setFactSet: (factSet) => set({ factSet }),
  setFilters: (filters) => set({ filters }),
}));

export const getValidFilters = (filters: Filter[], id: string) => {
  return filters.filter((x) => x.targets.includes(id));
};

export const applyFilters = (
  filters: Filter[],
  data: Record<string, string | number | Date>[]
) => {
  return data
    ? data.filter((row) => {
        return filters.every((fn) => fn.filterFn(row));
      })
    : [];
};
