import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Dialog from "@mui/material/Dialog/Dialog";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "utils/firebase";

export interface CreateMetricDialogProps {
  close: () => void;
}

export const CreateMetricDialog = ({ close }: CreateMetricDialogProps) => {
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const onCreate = async () => {
    const collectionRef = collection(firestore, "metrics");

    await addDoc(collectionRef, {
      description,
      title,
      tags:[]
    });
    close();
  };
  return (
    <Dialog open fullWidth maxWidth="md" style={{ minHeight: 400 }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          gap: 10,
        }}
      >
        <Typography variant="h5" textAlign="center">
          Create A Metric
        </Typography>
        <TextField
          value={title}
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          value={description}
          label="Description"
          multiline
          minRows={5}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box display={"flex"} flexDirection="row" justifyContent="space-evenly">
          <Button variant="contained" onClick={close}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onCreate}>
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
