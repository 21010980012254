import React from "react";
import Paper from "@mui/material/Paper/Paper";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import { Metric } from "types/Metric";
import { IconButton, Typography } from "@mui/material";
import { SQLIcon } from "components/icons/sql";
import { Link as RouterLink } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/system/Box/Box";

export interface MetricTableProps {
  metrics: Metric[];
  onOpenSettings: (metric: Metric) => void;
}

export const MetricTable = ({ metrics, onOpenSettings }: MetricTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Metric ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((row) => (
            <TableRow
              key={row.metricID}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell key={row.id} component="th" scope="row">
                <Typography>{row.metricID}</Typography>
                <Typography>({row.id})</Typography>
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>
                <Box display="flex" flexDirection="row">
                  <IconButton
                    onClick={() => {
                      onOpenSettings(row);
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <RouterLink to={`/metrics/${row.id}`}>
                    <IconButton>
                      <SQLIcon />
                    </IconButton>
                  </RouterLink>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
