import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import { ArrayEditor } from "components/ArrayEditor";
import { Metric, Visual, VisualSchemaItem } from "types/Dashboard";
import { pascalToTitle } from "utils/helpers";

export type EditItemProps<T> = {
  currentValue: any;
  metrics: Record<string, any>;
  liveMetrics: Metric[];
  targetVisual: Visual;
  schema: VisualSchemaItem<T>;
  currentMetric: any;
  setTargetVisual: React.Dispatch<React.SetStateAction<Visual>>;
  setCurrentMetric: React.Dispatch<any>;
};

export function EditItem({
  schema,
  currentValue,
  currentMetric,
  metrics,
  liveMetrics,
  targetVisual,
  setTargetVisual,
  setCurrentMetric,
}: EditItemProps<any>): JSX.Element {
  return {
    Metrics: (
      <Autocomplete
        freeSolo={schema.freeSolo}
        isOptionEqualToValue={(o, v) => v.id === o.id || (v as any) === o.id}
        id="metric-id"
        renderInput={(params: any) => <TextField {...params} label="Metric" />}
        value={
          {
            id: currentMetric.metricID,
            label: currentMetric?.title ?? "",
          } as any
        }
        inputValue={`${currentMetric.metricID}: ${currentMetric?.title}`}
        inputMode="text"
        options={
          liveMetrics
            ? Array.from(liveMetrics).map((x) => ({
                label: metrics.find((m: any) => m.metricID === x.id).title,
                id: x.id,
              }))
            : []
        }
        getOptionLabel={(x) => `${x.id}: ${x.label}`}
        onChange={(e, v) => {
          setTargetVisual({
            ...targetVisual,
            metricID: v?.id ?? -1,
          });
          setCurrentMetric(metrics.find((x: any) => x.metricID === v.id));
        }}
        ListboxProps={{ style: { height: 100 } }}
      />
    ),
    None: (
      <TextField
        fullWidth
        label={schema.field.toString()}
        value={currentValue}
        onChange={(e) =>
          setTargetVisual({
            ...targetVisual,
            visualProperties: {
              ...targetVisual.visualProperties,
              [schema.field]: e.target.value as any,
            } as any,
          })
        }
      />
    ),
    MetricColumns: (
      <Autocomplete
        disablePortal
        freeSolo={schema.freeSolo}
        id="combo-box-demo"
        options={
          (currentMetric.fields
            ? (currentMetric.fields as any).map((m: any) => m.columnName)
            : []) as string[]
        }
        getOptionLabel={(x) => `${x}`}
        sx={{ width: 300 }}
        defaultValue={currentValue}
        renderInput={(params) => (
          <TextField {...params} label={schema.field.toString()} />
        )}
        onInputChange={(e, v) =>
          setTargetVisual({
            ...targetVisual,
            visualProperties: {
              ...targetVisual.visualProperties,
              [schema.field]: v as any,
            } as any,
          })
        }
        onChange={(e, v) =>
          setTargetVisual({
            ...targetVisual,
            visualProperties: {
              ...targetVisual.visualProperties,
              [schema.field]: v as any,
            } as any,
          })
        }
        ListboxProps={{ style: { height: 100 } }}
      />
    ),
    FixedList: (
      <Autocomplete
        disablePortal
        freeSolo={schema.freeSolo}
        id="combo-box-demo"
        options={schema.fixedList ?? []}
        getOptionLabel={(x) => `${x}`}
        sx={{ width: 300 }}
        defaultValue={currentValue}
        renderInput={(params) => (
          <TextField {...params} label={schema.field.toString()} />
        )}
        onInputChange={(e, v) =>
          setTargetVisual({
            ...targetVisual,
            visualProperties: {
              ...targetVisual.visualProperties,
              [schema.field]: v as any,
            } as any,
          })
        }
        onChange={(e, v) =>
          setTargetVisual({
            ...targetVisual,
            visualProperties: {
              ...targetVisual.visualProperties,
              [schema.field]: v as any,
            } as any,
          })
        }
        ListboxProps={{ style: { height: 100 } }}
      />
    ),
    ArrayEditor: (
      <>
        <Typography>{pascalToTitle(schema.field.toString())}</Typography>
        <ArrayEditor
          numbers={currentValue}
          arrayType={schema.arrayType}
          onEdit={(n) => {
            setTargetVisual({
              ...targetVisual,
              visualProperties: {
                ...targetVisual.visualProperties,
                [schema.field]: n as any,
              } as any,
            });
          }}
        />
      </>
    ),
    ColumnValues: <></>,
  }[schema.inputType];
}
