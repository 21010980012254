import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog/Dialog";
import { firestore } from "utils/firebase";
import {
  collectionGroup,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Button, TextField, Typography } from "@mui/material";
import { Dashboard as TDashboard } from "types/Dashboard";
import Box from "@mui/system/Box/Box";
import { EditSharedUsers } from "./EditView/EditSharedUsers";
import { useUIStore } from "utils/UIStore";

export interface EditSettingsDialogProps {
  onClose: () => void;
  dashboardID: string;
}

export const EditSettingsDialog = ({
  onClose,
  dashboardID,
}: EditSettingsDialogProps) => {
  const user = useUIStore((state) => state.user);
  const [dashboardDetails, setDashboardDetails] = useState<TDashboard>();

  useEffect(() => {
    const fetchDashboard = async () => {
      if (user && dashboardID) {
        const sharedWithQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("uid", "==", dashboardID),
          where("owner", "==", user.uid)
        );
        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: TDashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as TDashboard;
          });
          setDashboardDetails(dashboards[0]);
        });
      }
    };
    fetchDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dashboardID]);

  const onSave = async () => {
    if (user && user.uid && dashboardID && dashboardDetails) {
      const docRef = doc(
        firestore,
        "users",
        user.uid,
        "dashboards",
        dashboardID
      );
      await updateDoc(docRef, "title", dashboardDetails?.title);
      await updateDoc(docRef, "sharedUsers", dashboardDetails?.sharedUsers);
    }
    onClose();
  };


  const onDelete = async() => {
    if (user?.uid && dashboardID) {
      const dashboardRef = doc(
        firestore,
        "users",
        user.uid,
        "dashboards",
        dashboardID
      );
      await deleteDoc(dashboardRef);
      onClose();
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        minHeight="200px"
        padding="16px"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        {dashboardDetails && (
          <>
            <Typography justifyContent="center"> Edit Dashboard</Typography>
            <TextField
              label="Title"
              fullWidth
              value={dashboardDetails.title}
              onChange={(e) =>
                setDashboardDetails({
                  ...dashboardDetails,
                  title: e.target.value ?? "",
                })
              }
            />
            <EditSharedUsers
              items={dashboardDetails.sharedUsers}
              onItemsChange={(items) =>
                setDashboardDetails({ ...dashboardDetails, sharedUsers: items })
              }
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
            >
              <Button
                variant="contained"
                color="error"
                onClick={onDelete}
                disabled={
                  !dashboardDetails || !(user?.uid ?? false)
                }
                style={{ minWidth: 150 }}
              >
                Delete!
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={onClose}
                style={{ minWidth: 150 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={onSave}
                disabled={
                  !dashboardDetails || !(user?.uid ?? false)
                }
                style={{ minWidth: 150 }}
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};
