import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import {
  Visual,
  VisualBarChart,
  VisualBasicFilter,
  VisualColumnChart,
  VisualDataGrid,
  VisualLineChart,
  VisualSchemaItem,
  VisualTextArea,
  VisualType,
} from "types/Dashboard";
import { Metric } from "types/Dashboard";
import { firestore } from "utils/firebase";
import { EditItem } from "./EditItem";
import { useUIStore } from "utils/UIStore";

export interface EditVisualDialogProps {
  editVisual: Visual;
  setEditVisual: React.Dispatch<React.SetStateAction<Visual | undefined>>;
  liveLayout: Visual[];
  setLiveLayout: React.Dispatch<React.SetStateAction<Visual[]>>;
  liveMetrics: Metric[];
}

const colorList = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#ADD8E6",
  "#FAFAD2",
  "#87CEFA",
  "#D2691E",
  "#FF6347",
  "#FFE4B5",
  "#FFA07A",
  "#9400D3",
  "#E6E6FA",
  "#ADFF2F",
  "#FF0000",
  "#00CED1",
  "#F0F8FF",
  "#A0522D",
  "#FFE4C4",
  "#FF4500",
  "#FFFF00",
  "#FFC0CB",
  "#800000",
  "#9932CC",
];

const visualSchema: {
  [K in VisualType]: (
    | VisualSchemaItem<VisualBarChart["visualProperties"]>
    | VisualSchemaItem<VisualColumnChart["visualProperties"]>
    | VisualSchemaItem<VisualLineChart["visualProperties"]>
    | VisualSchemaItem<VisualTextArea["visualProperties"]>
    | VisualSchemaItem<VisualBasicFilter["visualProperties"]>
    | VisualSchemaItem<VisualDataGrid["visualProperties"]>
  )[];
} = {
  BarChart: [
    { field: "metricID", inputType: "Metrics" },
    { field: "topN", inputType: "None", freeSolo: true },
    {
      field: "color",
      inputType: "FixedList",
      fixedList: colorList,
      freeSolo: true,
    },
    { field: "xKey", inputType: "MetricColumns", freeSolo: false },
    { field: "yKey", inputType: "MetricColumns", freeSolo: false },
  ] as VisualSchemaItem<VisualBarChart["visualProperties"]>[],
  BasicFilter: [
    { field: "metricID", inputType: "Metrics" },
    {
      field: "filterID",
      inputType: "None",
      freeSolo: false,
      disabled: true,
    },
    { field: "initialValue", inputType: "None", freeSolo: true },
    { field: "targets", inputType: "ArrayEditor", arrayType: "number" },
    { field: "valueKey", inputType: "MetricColumns" },
  ] as VisualSchemaItem<VisualBasicFilter["visualProperties"]>[],
  ColumnChart: [
    { field: "metricID", inputType: "Metrics" },
    {
      field: "color",
      inputType: "FixedList",
      fixedList: colorList,
      freeSolo: true,
    },
    { field: "xKey", inputType: "MetricColumns" },
    { field: "yKey", inputType: "MetricColumns" },
  ] as VisualSchemaItem<VisualColumnChart["visualProperties"]>[],
  LineChart: [
    { field: "metricID", inputType: "Metrics" },
    {
      field: "color",
      inputType: "FixedList",
      fixedList: colorList,
      freeSolo: true,
    },
    { field: "xKey", inputType: "MetricColumns" },
    { field: "yKey", inputType: "MetricColumns" },
  ] as VisualSchemaItem<VisualLineChart["visualProperties"]>[],
  TextArea: [
    { field: "body", inputType: "None", freeSolo: true },
    {
      field: "variant",
      inputType: "FixedList",
      fixedList: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h5",
        "h6",
        "body1",
        "body2",
        "subtitle1",
        "subtitle2",
        "caption",
      ],
    },
    {
      field: "color",
      inputType: "FixedList",
      fixedList: colorList,
      freeSolo: true,
    },
    {
      field: "textAlign",
      inputType: "FixedList",
      fixedList: ["left", "center", "right", "justify"],
    },
  ] as VisualSchemaItem<VisualTextArea["visualProperties"]>[],
  DataGrid: [
    { field: "metricID", inputType: "Metrics" },
    {
      field: "columns",
      inputType: "ArrayEditor",
      arrayType: "text",
      optionsGetter: "MetricColumns",
    },
    {
      field: "columnWidths",
      inputType: "ArrayEditor",
      arrayType: "number",
    },
  ] as VisualSchemaItem<VisualDataGrid["visualProperties"]>[],
};

export const EditVisualDialog = ({
  editVisual,
  setEditVisual,
  liveLayout,
  setLiveLayout,
  liveMetrics,
}: EditVisualDialogProps) => {
  const user = useUIStore((state) => state.user);
  const [targetVisual, setTargetVisual] = useState(editVisual);
  const [metrics, setMetrics] = useState<Record<string, any>>([]);
  const [currentMetric, setCurrentMetric] = useState<any>(null);

  const targetSchema = visualSchema[targetVisual.visualType];

  useEffect(() => {
    const fetchMetrics = async () => {
      if (user) {
        const metricsRef = collection(firestore, "metrics");

        // Create a query against the collection.
        const q = query(
          metricsRef,
          where("visibility", "==", "public"),
          where("status", "==", "valid")
        );

        onSnapshot(q, (querySnapshot) => {
          const metricsRaw: Metric[] = [];
          querySnapshot.forEach((doc) => {
            metricsRaw.push({ id: doc.id, ...doc.data() } as Metric);
          });
          setMetrics(metricsRaw);
          setCurrentMetric(
            metricsRaw.find((x: any) => x.metricID === targetVisual.metricID) ??
              metricsRaw[0]
          );
        });
      }
    };
    fetchMetrics();
  }, [user, targetVisual.metricID]);

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          padding: 10,
          maxHeight: 500,
        }}
      >
        {metrics.length > 0 && targetVisual && (
          <>
            {metrics.length > 0 &&
              targetVisual &&
              currentMetric &&
              targetSchema.map((schemaItem) => {
                const value: any = (targetVisual as any).visualProperties[
                  schemaItem.field
                ];

                return (
                  <EditItem
                    schema={schemaItem}
                    metrics={metrics}
                    liveMetrics={liveMetrics}
                    targetVisual={targetVisual}
                    currentValue={value}
                    setCurrentMetric={setCurrentMetric}
                    setTargetVisual={setTargetVisual}
                    currentMetric={currentMetric}
                  />
                );
              })}
          </>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => setEditVisual(undefined)}
          style={{ width: "10em" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          style={{ width: "10em" }}
          onClick={() => {
            const editID = targetVisual.id;
            const rest = liveLayout.filter((viz) => viz.id !== editID);
            if (targetVisual) {
              setLiveLayout([
                ...rest,
                { ...targetVisual, metricID: currentMetric.metricID },
              ]);
              setEditVisual(undefined);
            }
          }}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
};
