import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MetricTestInfo } from "types/Metric";

export interface TestDataTableProps {
  rows: Record<string, unknown>[];
  rowInfo: MetricTestInfo[];
}

export const TestDataTable = ({ rows, rowInfo }: TestDataTableProps) => {
  const columns: GridColDef[] = rowInfo.map((info) => {
    return {
      field: info.columnName,
      width: 200,
      valueFormatter: (params) => params?.value?.value ?? params?.value ?? "",
      type: { INT64: "number", FLOAT64: "number" }?.[info.dataType] ?? "string",
    };
  });

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows.map((x, i) => {
          return { id: i, ...x };
        })}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
