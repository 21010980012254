import React, { useState } from "react";
import { Button, TextField, List, ListItem, ListItemText } from "@mui/material";
import Box from "@mui/system/Box/Box";

interface EditSharedUsersProps {
  items: string[];
  onItemsChange: (items: string[]) => void;
}

export const EditSharedUsers = ({
  items,
  onItemsChange,
}: EditSharedUsersProps) => {
  const [newItem, setNewItem] = useState("");

  const handleAddItem = () => {
    onItemsChange([...items, newItem]);
    setNewItem("");
  };

  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onItemsChange(newItems);
  };

  return (
    <div>
      <Box display={"flex"} flexDirection="row" justifyContent={"space-between"} gap={10}>
        <TextField
          label="New User"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          fullWidth
          size="small"
        />
        <Button variant="contained" onClick={handleAddItem} fullWidth>
          Add Item
        </Button>
      </Box>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
            <Button variant="contained" onClick={() => handleRemoveItem(index)}>
              Remove
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
