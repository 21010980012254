import React, { useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  checkNotificationPermission,
  requestPermission,
} from "utils/notifications";
import Box from "@mui/system/Box/Box";
import AddAlertIcon from "@mui/icons-material/AddAlert";

type Notification = {
  name: string;
  collection: string;
  active: boolean;
  details: string;
};

export const NotificationsView = () => {
  const [notifications] = useState<Notification[]>([]);
  const [enableNotifications, setEnableNotifications] = useState(
    checkNotificationPermission()
  );

  const toggleNotifications = () => {
    setEnableNotifications(!enableNotifications);
    // logic to enable/disable notifications
    requestPermission();
  };

  return (
    <Box style={{ padding: 16 }} gap={8}>
      {!enableNotifications && (
        <Button onClick={toggleNotifications} variant="contained">
          {enableNotifications
            ? "Disable Notifications"
            : "Enable Notifications"}
        </Button>
      )}
      <IconButton style={{ float: "right" }}>
        <AddAlertIcon />
      </IconButton>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Collection</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((notification) => (
            <TableRow key={notification.name}>
              <TableCell>{notification.name}</TableCell>
              <TableCell>{notification.collection}</TableCell>
              <TableCell>
                {notification.active ? "Active" : "Inactive"}
              </TableCell>
              <TableCell>{notification.details}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
