import React, { useState } from "react";
import Dialog from "@mui/material/Dialog/Dialog";
import { auth, firestore } from "utils/firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/system/Box/Box";
import { useUIStore } from "utils/UIStore";

export interface NewDashboardDialogProps {
  onClose: () => void;
}

export const NewDashboardDialog = ({ onClose }: NewDashboardDialogProps) => {
  const user = useUIStore((state) => state.user);
  const [title, setTitle] = useState("");

  const onCreate = async () => {
    if (user) {
      const now = new Date();
      const docRef = doc(
        collection(firestore, "users", user?.uid, "dashboards")
      );
      const uid = docRef.id;
      await setDoc(docRef, {
        uid,
        config: { grid: { x: 18 } },
        layout: [],
        createdAt: now,
        lastEdited: now,
        filters: [],
        metrics: [],
        sharedUsers: [],
        status: "Private",
        title,
        owner: user.uid,
      });
      onClose();
    } else {
      new Error("Not Logged In");
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        minHeight="200px"
        padding="16px"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Typography justifyContent="center"> Create A New Dashboard</Typography>
        <TextField
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-evenly">
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onCreate}
            disabled={!title || !(auth?.currentUser?.uid ?? false)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
