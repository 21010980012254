import React, { useState } from "react";
import { Typography, Rating, TextField, Button, Box } from "@mui/material";
import { httpsCallableFromURL } from "firebase/functions";
import { functions } from "utils/firebase";

const naturallangqueryreview = httpsCallableFromURL(
  functions,
  "https://naturallangqueryreview-fdqnt6qvaa-ts.a.run.app"
);

export const QnAFeedback = ({
  uuid,
  onFeedbackSent,
}: {
  uuid: string;
  onFeedbackSent: () => void;
}) => {
  const [ratingValue, setRatingValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");

  const handleRatingChange = (event: any, value: number | null) => {
    if (value !== null) setRatingValue(value);
  };

  const handleCommentChange = (event: any) => {
    setCommentValue(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // handle submission logic here
    const response = await naturallangqueryreview({
      rating: ratingValue,
      comment: commentValue,
      uuid: uuid,
    });

    if (response) {
      onFeedbackSent();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Rate the response:
      </Typography>
      <Rating
        sx={{ margin: 2 }}
        name="rating"
        value={ratingValue}
        precision={1}
        onChange={handleRatingChange}
      />
      <TextField
        sx={{ margin: 2, width: "100%" }}
        label="Comment (optional)"
        multiline
        rows={4}
        value={commentValue}
        onChange={handleCommentChange}
      />
      <Button
        sx={{ margin: 2 }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Box>
  );
};
