import { User } from "firebase/auth";
import { unstable_batchedUpdates } from "react-dom";
import { create } from "zustand";
import { auth } from "./firebase";

export type State = {
  user: User | null;
  navDrawOpen: boolean;
  theme: "light" | "dark";
};

export type Actions = {
  setUser: (user: User | null) => void;
  setNavDrawOpen: (status: boolean) => void;
  setTheme: (theme: "light" | "dark") => void;
};

export type UIStore = State & Actions;

const initialUIStore: State = {
  user: auth.currentUser,
  navDrawOpen: false,
  theme: "light",
};

export const useUIStore = create<UIStore>((set) => ({
  ...initialUIStore,
  setUser: (user) => set({ user }),
  setNavDrawOpen: (navDrawOpen) => set({ navDrawOpen }),
  setTheme: (theme) => set({ theme }),
}));

export const nonReactSetUser = (user: User | null) => {
  unstable_batchedUpdates(() => {
    useUIStore.getState().setUser(user);
  });
};
