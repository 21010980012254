import React, { useState } from "react";
import { sendSignInLinkToEmail } from "firebase/auth";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import { auth } from "utils/firebase";

export const FirebaseLoginForm = () => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const handleSendEmailLink = () => {
    const actionCodeSettings = {
      url: `${document.URL}`,
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setSent(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box style={{ margin: "10px" }}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack spacing={2} width="100%">
          <Typography variant="h4" width="100%" textAlign="center">
            Metric.Club
          </Typography>
          <Typography variant="overline" width="100%" textAlign="center">
            login with your email
          </Typography>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmailLink}
            disabled={!email || sent}
            fullWidth
          >
            {sent ? "Email sent" : "Send email"}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
