import React from "react";
import Button from "@mui/material/Button";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useUIStore } from "utils/UIStore";
//Icons

// Firebase Imports
import { signOut } from "firebase/auth";
import { auth } from "utils/firebase";
import Dialog from "@mui/material/Dialog/Dialog";
import { useState } from "react";
import { FirebaseLoginForm } from "components/auth/firebaseUI/FirebaseLoginForm";
import Box from "@mui/material/Box/Box";
import { Link as RouterLink } from "react-router-dom";
import { ThemeToggleButton } from "components/theme/ThemeButton";

export default function AppBar() {
  const setNavDrawOpen = useUIStore((state) => state.setNavDrawOpen);

  const user = useUIStore((state) => state.user);
  const [signInOpen, setSignInOpen] = useState(false);
  // eslint-disable-next-line
  const [open] = useState(false);
  console.log(user);

  const handleDrawerOpen = () => {
    setNavDrawOpen(true);
  };

  const logout = () => {
    signOut(auth);
  };

  return (
    <>
      <Dialog open={signInOpen} fullWidth maxWidth="xs">
        {signInOpen && <FirebaseLoginForm />}
      </Dialog>
      <MuiAppBar position="static" color="transparent">
        <Toolbar>
          {!!user && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            fontFamily="monospace"
            variant="h4"
            noWrap
            component="div"
            color="#000080"
          >
            <strong>Metric.Club</strong>
          </Typography>
          <Box>
            <Typography
              fontFamily={"monospace"}
              variant="h5"
              color={"red"}
              style={{
                position: "relative",
                transform: "rotate(-20deg)",
                transformOrigin: "center",
                top: "0.5em",
                left: "-0.3em",
                borderTop: 0,
                border: 2,
                borderRadius: 10,
                borderWidth: 2,
                borderStyle: "solid",
              }}
            >
              BETA
            </Typography>
            <Typography
              fontFamily={"monospace"}
              variant="h5"
              style={{ visibility: "hidden" }}
            >
              BETA
            </Typography>
          </Box>
          {user ? (
            <Box component={RouterLink}>
              <Button
                color="inherit"
                onClick={logout}
                style={{ float: "right" }}
              >
                <Typography>Logout</Typography>
              </Button>
            </Box>
          ) : (
            <Button
              color="inherit"
              onClick={() => setSignInOpen(true)}
              style={{ float: "right" }}
            >
              Login
            </Button>
          )}
          <ThemeToggleButton />
        </Toolbar>
      </MuiAppBar>
    </>
  );
}
