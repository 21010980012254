import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { MetricDashboard } from "./Dashboards/MetricDashboard";
import { collectionGroup, onSnapshot, query, where } from "firebase/firestore";
import { firestore, functions } from "utils/firebase";
import { Dashboard as TDashboard } from "types/Dashboard";
import { httpsCallableFromURL } from "firebase/functions";
import { useFactStore } from "components/Dashboard/DataStore";
import SqlHighlighter from "components/SQLHighliter";

const getMetricByID = httpsCallableFromURL(
  functions,
  "https://querybyid-fdqnt6qvaa-ts.a.run.app"
);

export const MainView = () => {
  const setFactSet = useFactStore((x) => x.setFactSet);
  const [dashboardDetails, setDashboardDetails] = useState<TDashboard>();
  useEffect(() => {
    const fetchDashboard = async () => {
      const demoQuery = query(
        collectionGroup(firestore, "dashboards"),
        where("uid", "==", "8eqUtlHp2d1ATl3acdwX"),
        where("status", "==", "Open")
      );

      onSnapshot(demoQuery, (querySnapshot) => {
        const docs = querySnapshot.docs;
        const dashboards: TDashboard[] = docs.map((d) => {
          return { id: d.id, ...d.data() } as TDashboard;
        });
        if (dashboards.length > 0 && !dashboardDetails) {
          setDashboardDetails(dashboards[0]);
        }
      });
    };
    fetchDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardDetails) {
      const fetchMetrics = async () => {
        if (dashboardDetails.metrics) {
          const metrics = dashboardDetails.metrics.map(async (m) => {
            const response = await getMetricByID({ id: m.id });
            const data = ((response as any).data as any).rows;
            return {
              [+m.id]: data,
            };
          });

          const resolved = await Promise.all(metrics);
          const metricsObject = resolved.reduce((a, b) => ({ ...a, ...b }), {});
          setFactSet(metricsObject);
        }
      };
      fetchMetrics();
    }
  }, [dashboardDetails, setFactSet]);
  return (
    <Box sx={{ bgcolor: "#F7F9FC" }}>
      <Container maxWidth="lg">
        <Box sx={{ py: 8 }}>
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 4 }}
            fontFamily="monospace"
          >
            Create Dashboards For IMX NFTs
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} md={12} lg={6}>
              <Box
                sx={{ height: "100%", display: "flex", alignItems: "center" }}
              >
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{ mb: 2 }}
                    fontFamily="monospace"
                  >
                    No SQL? No Problem!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 4 }}
                    fontFamily="monospace"
                    textAlign="justify"
                  >
                    Create Dashboards using predefined metrics created by members
                    of Metric.Club. This allows anyone to create a dashboard in
                    minutes no skills requried.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <img
                src="https://via.placeholder.com/800x600.png"
                alt="Feature 1"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box
                sx={{ height: "100%", display: "flex", alignItems: "center" }}
              >
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{ mb: 2 }}
                    fontFamily="monospace"
                  >
                    Feeling Nerdy?
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 2 }}
                    fontFamily="monospace"
                    color={"red"}
                  >
                    Comming Soon
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 4 }}
                    fontFamily="monospace"
                    textAlign="justify"
                  >
                    Dive into the data and use your SQL skils to LEAD (or LAG)
                    your way to defing new metrics and insights to share with
                    the world.... or just your friends.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <SqlHighlighter
                sql={`
SELECT
    collection,
    soldDate,
    soldYear,
    soldMonth,
    soldDay,
    rewardID,
    rewardName,
    rarity,
    season,
    totalSupply,
    SUM(soldPrice) AS vol,
    COUNT(*) AS salesCount
FROM
    \`%metric_1%\`
WHERE
    tokenType = "ETH"
GROUP BY
    collection,
    soldDate,
    soldYear,
    soldMonth,
    soldDay,
    rewardID,
    rewardName,
    rarity,
    season,
    totalSupply
                `}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="h4"
                sx={{ mb: 2 }}
                style={{ padding: 2 }}
                fontFamily="monospace"
                textAlign="center"
              >
                Here's one we prepared earlier
              </Typography>
              {dashboardDetails && (
                <MetricDashboard dashboardDetails={dashboardDetails} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
