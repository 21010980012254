import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  styled,
} from "@mui/material";
import { ConversationItem, ConversationData } from "types/QnA";

type Props = {
  conversation: ConversationItem[];
  onEdit: (item: ConversationItem) => void;
};

const ConversationList: React.FC<Props> = ({ conversation, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<ConversationItem | null>(null);
  const [editValue, setEditValue] = useState<string>("");

  const handleEditClick = (item: ConversationItem) => {
    setItemToEdit(item);
    setEditValue(item.data[0].content);
    setOpen(true);
  };

  const handleEditSave = () => {
    if (itemToEdit) {
      const newData: ConversationData[] = [
        { content: editValue, role: itemToEdit.data[0].role },
      ];
      const newItem: ConversationItem = { ...itemToEdit, data: newData };
      onEdit(newItem);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledListItem = styled(ListItem)({
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "10px",
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  });

  const StyledListItemText = styled(ListItemText)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > *": {
      margin: "5px 0",
    },
  });

  const StyledDialogTitle = styled(DialogTitle)({
    textAlign: "center",
  });

  const StyledDialogContent = styled(DialogContent)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  const StyledDialogActions = styled(DialogActions)({
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  });

  const StyledButton = styled(Button)({
    margin: "0 5px",
  });

  return (
    <div>
      <List>
        {conversation.map((item) => (
          <>
            {item.data.map((data, index) => (
              <StyledListItem
                key={item.id}
                onClick={() => handleEditClick(item)}
              >
                <StyledListItemText
                  primary={`Role: ${data.role}`}
                  secondary={`${data.content}`}
                />
              </StyledListItem>
            ))}
          </>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <StyledDialogTitle>Edit Conversation Item</StyledDialogTitle>
        <StyledDialogContent>
          <TextField
            fullWidth
            label="Content"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={handleClose}>Cancel</StyledButton>
          <StyledButton onClick={handleEditSave}>Save</StyledButton>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default ConversationList;
