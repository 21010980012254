export type Data = {
  token_address: string;
  token_id: string;
  id: string;
  user: string;
  status: string;
  uri: null;
  name: string;
  description: string;
  image_url: string;
  metadata: {
    name: string;
    type: string;
    rarity: string;
    season: string;
    rewardID: string;
    image_url: string;
    redeemable: boolean;
    description: string;
    serialNumber: string;
  };
  collection: {
    name: string;
    icon_url: string;
  };
  created_at: string;
  updated_at: string;
};

type GroupedData = {
  [season: string]: {
    [name: string]: {
      count: number;
      data: Data[];
    };
  };
};
export const groupDataBySeason = (data: Data[]): GroupedData => {
  const groupedData: GroupedData = {};

  data.forEach((item) => {
    const { season, name } = item.metadata;

    if (!groupedData[season]) {
      groupedData[season] = {};
    }

    if (!groupedData[season][name]) {
      groupedData[season][name] = {
        count: 1,
        data: [item],
      };
    } else {
      groupedData[season][name].count++;
      groupedData[season][name].data.push(item);
    }
  });

  return groupedData;
};
