import { StoreLineChart } from "components/Dashboard/components/StoreLineChart";
import { Dashboard } from "components/Dashboard/Dashboard";
import { ErrorBoundary } from "react-error-boundary";
import { Layout } from "react-grid-layout";
import { DashboardEditorButtons } from "./DashboardEditorButtons";

import { Dashboard as TDashboard, Visual } from "types/Dashboard";
import { StoreBarChart } from "components/Dashboard/components/StoreBarChart";
import Typography from "@mui/material/Typography/Typography";
import { StoreColumnChart } from "components/Dashboard/components/StoreColumnChart";
import { StoreBasicFilter } from "components/Dashboard/components/StoreBasicFilter";
import { StoreDataGrid } from "components/Dashboard/components/StoreDataGrid";

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

export interface MetricDashboardProps {
  dashboardDetails: TDashboard;
  onDrop?: (layout: Layout[], layoutItem: Layout, _event: DragEvent) => void;
  onResizeStop?: (layout: Layout[]) => void;
  layoutOverride?: Visual[];
  backgroundColor?: string;
  setLayout?: React.Dispatch<React.SetStateAction<Visual[]>>;
  setEditVisual?: React.Dispatch<React.SetStateAction<Visual | undefined>>;
}

export const MetricDashboard = ({
  dashboardDetails,
  setLayout,
  layoutOverride,
  backgroundColor,
  onDrop,
  onResizeStop,
  setEditVisual,
}: MetricDashboardProps) => {
  const layout = layoutOverride ? layoutOverride : dashboardDetails.layout;
  const isStatic = !(onDrop && onResizeStop && setEditVisual);
  return (
    <Dashboard
      config={{
        ...dashboardDetails.config,
        backgroundColor:
          backgroundColor ?? dashboardDetails.config.backgroundColor,
      }}
      onDrop={onDrop}
      onResizeStop={onResizeStop}
      onLayoutChange={(current, all) => {
        onResizeStop && onResizeStop(current);
      }}
    >
      {layout.map((viz, i) => {
        if (viz.visualType === "BarChart") {
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StoreBarChart
                  {...{
                    key: viz.id,
                    id: viz.id,
                    metricID: viz.metricID ?? "",
                    ...viz.visualProperties,
                  }}
                />
              </ErrorBoundary>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else if (viz.visualType === "TextArea") {
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <Typography {...viz.visualProperties}>
                {viz.visualProperties.body}
              </Typography>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else if (viz.visualType === "LineChart") {
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StoreLineChart
                  {...{
                    key: viz.id,
                    id: viz.id,
                    metricID: viz.metricID ?? "",
                    ...viz.visualProperties,
                    dataLimit: 256,
                  }}
                />
              </ErrorBoundary>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else if (viz.visualType === "ColumnChart") {
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StoreColumnChart
                  {...{
                    key: viz.id,
                    id: viz.id,
                    metricID: viz.metricID ?? "",
                    ...viz.visualProperties,
                    dataLimit: 256,
                  }}
                />
              </ErrorBoundary>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else if (viz.visualType === "BasicFilter") {
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StoreBasicFilter
                  {...{
                    key: viz.id,
                    id: viz.id,
                    metricID: viz.metricID ?? "",
                    ...viz.visualProperties,
                  }}
                />
              </ErrorBoundary>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else if (viz.visualType === "DataGrid") {
          console.log("viz.visualProperties", viz.visualProperties);
          return (
            <div
              key={viz.id}
              style={{
                boxShadow: "4 4",
                height: "100%",
                width: "100%",
              }}
              data-grid={{ ...viz.position, static: isStatic }}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StoreDataGrid
                  {...{
                    key: viz.id,
                    id: viz.id,
                    metricID: viz.metricID ?? "",
                    ...viz.visualProperties,
                  }}
                />
              </ErrorBoundary>
              {setLayout && setEditVisual && (
                <DashboardEditorButtons
                  setLiveLayout={setLayout}
                  liveLayout={layout}
                  viz={viz}
                  setEditVisual={setEditVisual}
                />
              )}
            </div>
          );
        } else {
          return <></>;
        }
      })}
    </Dashboard>
  );
};
