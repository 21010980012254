import { useState, useEffect } from "react";
import { firestore } from "utils/firebase";
import { UserData } from "types/User";
import { doc, onSnapshot } from "firebase/firestore";
import { useUIStore } from "utils/UIStore";
export const useGetUserDetails = (): [UserData | undefined, boolean] => {
  const user = useUIStore((state) => state.user);
  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState<UserData>();

  useEffect(() => {
    if (user) {
      const fetchUserDetails = async () => {
        const docRef = doc(
          firestore,
          "users",
          user.uid,
          "private_data",
          "public"
        );

        onSnapshot(docRef, (querySnapshot) => {
          const userData = querySnapshot.data();
          setUserData(userData as UserData);
          setLoading(false);
        });
      };
      fetchUserDetails();
    }
  }, [user]);

  return [userData, loading];
};
