import Button from "@mui/material/Button/Button";
import React from "react";
import {
  requestPermission,
  registerNotificationHandler,
} from "utils/notifications";

export interface SettingsViewProps {}

export const SettingsView = () => {
  return (
    <>
      <Button
        onClick={() => {
          requestPermission();
          registerNotificationHandler();
        }}
      ></Button>
    </>
  );
};
