import React, { useState } from "react";
import { Button, Chip, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { pascalToTitle } from "utils/helpers";

interface ArrayEditorProps {
  numbers: string[];
  onEdit: (newNumbers: string[]) => void;
  arrayType?: "text" | "number";
}

export const ArrayEditor = ({
  numbers,
  onEdit,
  arrayType,
}: ArrayEditorProps) => {
  const [newNumber, setNewNumber] = useState<string>("");
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const handleAddNumber = () => {
    const newNumbers = [...numbers, newNumber];
    onEdit(newNumbers);
    setNewNumber("");
  };

  const handleRemoveNumber = (index: number) => {
    const newNumbers = numbers.filter((_, i) => i !== index);
    onEdit(newNumbers);
  };

  const handleDragStart = (index: number) => {
    setDragIndex(index);
  };

  const handleDragOver = (index: number) => {
    setHoverIndex(index);
  };

  const handleDrop = () => {
    if (dragIndex !== null && hoverIndex !== null && dragIndex !== hoverIndex) {
      const newNumbers = [...numbers];
      const draggedItem = newNumbers[dragIndex];
      newNumbers.splice(dragIndex, 1);
      newNumbers.splice(hoverIndex, 0, draggedItem);
      onEdit(newNumbers);
    }
    setDragIndex(null);
    setHoverIndex(null);
  };

  const handleDragEnd = () => {
    setDragIndex(null);
    setHoverIndex(null);
  };

  const renderNumberChip = (number: string, index: number) => {
    const isDragging = dragIndex === index;
    const isHovering = hoverIndex === index;

    return (
      <div
        key={index}
        style={{
          display: "inline-flex",
          margin: "4px",
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
        draggable
        onDragStart={() => handleDragStart(index)}
        onDragOver={(e) => {
          e.preventDefault();
          handleDragOver(index);
        }}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
      >
        <Chip
          label={pascalToTitle(number)}
          onDelete={() => handleRemoveNumber(index)}
          color="primary"
          variant={isHovering ? "outlined" : "filled"}
        />
      </div>
    );
  };

  return (
    <div>
      {numbers.map(renderNumberChip)}
      <div style={{ margin: "8px 0" }}>
        <TextField
          type={arrayType ?? "number"}
          value={newNumber}
          onChange={(e) => setNewNumber(String(e.target.value))}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddNumber}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
