import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { firestore, functions } from "utils/firebase";
import { where, collectionGroup, query, onSnapshot } from "firebase/firestore";
import { Dashboard as TDashboard } from "types/Dashboard";
import { useFactStore } from "components/Dashboard/DataStore";
import { httpsCallableFromURL } from "firebase/functions";
import { MetricDashboard } from "./MetricDashboard";
import { useUIStore } from "utils/UIStore";

const getMetricByID = httpsCallableFromURL(
  functions,
  "https://querybyid-fdqnt6qvaa-ts.a.run.app"
);

export const DashboardViewer = () => {
  const user = useUIStore((state) => state.user);
  const location = useLocation();
  const [dashboardDetails, setDashboardDetails] = useState<TDashboard>();
  const setFactSet = useFactStore((x) => x.setFactSet);
  const [{ params }] = matchRoutes(
    [{ path: "dashboards/:dashboardID" }],
    location
  ) ?? [{ params: {} }];

  const dashboardID = params.dashboardID;
  console.log("dc", dashboardDetails);

  useEffect(() => {
    const fetchDashboard = async () => {
      if (user && params && dashboardID) {
        console.log("Running", user.uid);
        const isOwnerQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("uid", "==", dashboardID),
          where("owner", "==", user.uid)
        );

        const sharedWithQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("uid", "==", dashboardID),
          where("sharedUsers", "array-contains", user.uid)
        );

        const adminQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("uid", "==", dashboardID)
        );

        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: TDashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as TDashboard;
          });
          if (dashboards.length > 0 && !dashboardDetails) {
            setDashboardDetails(dashboards[0]);
          }
        });

        onSnapshot(isOwnerQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: TDashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as TDashboard;
          });
          if (dashboards.length > 0 && !dashboardDetails) {
            setDashboardDetails(dashboards[0]);
          }
        });

        onSnapshot(adminQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: TDashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as TDashboard;
          });
          if (dashboards.length > 0 && !dashboardDetails) {
            setDashboardDetails(dashboards[0]);
          }
        });
      }
    };
    fetchDashboard();
  }, [user, dashboardID, params, dashboardDetails]);

  useEffect(() => {
    if (dashboardDetails) {
      const fetchMetrics = async () => {
        if (dashboardDetails.metrics) {
          const metrics = dashboardDetails.metrics.map(async (m) => {
            const response = await getMetricByID({ id: m.id });
            const data = ((response as any).data as any).rows;
            return {
              [+m.id]: data,
            };
          });

          const resolved = await Promise.all(metrics);
          const metricsObject = resolved.reduce((a, b) => ({ ...a, ...b }), {});
          setFactSet(metricsObject);
        }
      };
      fetchMetrics();
    }
  }, [dashboardDetails, setFactSet]);

  return (
    <>
      {dashboardDetails && (
        <>
          <MetricDashboard dashboardDetails={dashboardDetails} />
        </>
      )}
    </>
  );
};
