import React from "react";

import Box from "@mui/material/Box/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { applyFilters, getValidFilters, useFactStore } from "../DataStore";
import { pascalToTitle } from "utils/helpers";

export interface StoreDataGridProps {
  id: string;
  metricID: number | string | null;
  columns: string[];
  columnWidths: number[];
}

export const StoreDataGrid = ({
  id,
  metricID,
  columns,
  columnWidths,
}: StoreDataGridProps) => {
  const factSet = useFactStore((state) => state.factSet);
  const filters = useFactStore((state) => state.filters);
  console.log("grid metricID", metricID);
  const data = applyFilters(
    getValidFilters(filters, id),
    factSet[metricID ?? ""]
  ).map((row, i) => ({ id: i, ...row }));
  const cols: GridColDef[] = columns.map((col, i) => {
    return {
      field: col,
      width: columnWidths?.[i] ?? 100,
      headerName: pascalToTitle(col),
      type:
        !isNaN(Number((data as any)?.[0]?.[col] ?? "")) &&
        ((data as any)?.[0]?.[col] ?? "")[1] !== "x"
          ? "number"
          : "string",
    };
  });

  return (
    // DataGrid
    <Box style={{ height: "100%", width: "100%" }}>
      {data.length > 0 && <DataGrid rows={data} columns={cols} />}
    </Box>
  );
};
