import React, { useEffect, useState } from "react";
import { onSnapshot } from "@firebase/firestore";
import { firestore, functions } from "utils/firebase";
import { httpsCallableFromURL } from "firebase/functions";
import { Metric, MetricTestInfo } from "types/Metric";
import { matchRoutes, useLocation } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { useGetUserDetails } from "hooks/useGetUserDetails";
import { Box, Button, Typography } from "@mui/material";
import CodeEditor from "react-simple-code-editor";
import Container from "@mui/system/Container/Container";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { format } from "sql-formatter";
import { TestDataTable } from "./TestDataTable";
import { useUIStore } from "utils/UIStore";

const testUserView = httpsCallableFromURL(
  functions,
  "https://testuserview-fdqnt6qvaa-ts.a.run.app"
);

export const MetricsEditView = () => {
  const user = useUIStore((state) => state.user);
  const [userData, loading] = useGetUserDetails();
  const location = useLocation();
  const [metric, setMetric] = useState<Metric>();
  const [query, setQuery] = useState<string>();
  const [testPassed, setTestPassed] = useState(false);
  const [testData, setTestData] = useState<Record<string, unknown>[]>([]);
  const [testDataColumns, setTestDataColumns] = useState<MetricTestInfo[]>([]);
  const [{ params }] = matchRoutes(
    [{ path: "metrics/:metricID" }],
    location
  ) ?? [{ params: {} }];

  useEffect(() => {
    if (user && params && params.metricID) {
      const fetchMasterMetrics = async () => {
        const docRef = doc(firestore, "metrics", params.metricID as string);
        onSnapshot(docRef, (querySnapshot) => {
          const data = querySnapshot.data();
          if (data) {
            setMetric(data as Metric);
            setQuery(data.query as string);
          }
        });
      };
      fetchMasterMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const testView = async () => {
    setTestPassed(false);
    const result = await testUserView({ query });
    console.log(result);
    if (result.data) {
      setTestData((result.data as any).rows as Record<string, unknown>[]);
      setTestDataColumns((result.data as any).rowInfo as MetricTestInfo[]);
      setTestPassed(true);
    }
  };

  const onSave = async () => {
    if (testPassed) {
      const docRef = doc(firestore, "metrics", params.metricID as string);

      await updateDoc(docRef, "query", query);
      await updateDoc(docRef, "fields", testDataColumns);
    }
  };

  return (
    <Container style={{ paddingTop: 32 }}>
      {!loading &&
        userData &&
        userData.membershipLevel >= 1000000 &&
        metric && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <Box height={500} overflow={"scroll"}>
              <CodeEditor
                value={query ?? "Enter your SQL query here."}
                onValueChange={(code) => {
                  setQuery(code);
                  setTestPassed(false);
                }}
                highlight={(code) => highlight(code, languages.sql, "sql")}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  backgroundColor: "lightgrey",
                  minHeight: 500,
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: 10,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (query) {
                    const formatted = format(query, { language: "bigquery" });
                    setQuery(formatted);
                  }
                }}
              >
                Format
              </Button>
              <Button variant="contained" onClick={testView}>
                Test
              </Button>
              <Button
                variant="contained"
                disabled={!testPassed}
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
            {!loading && userData && userData.membershipLevel < 1_000_000 && (
              <Typography> Can't Do That!</Typography>
            )}
            {testData && testDataColumns && (
              <TestDataTable rows={testData} rowInfo={testDataColumns} />
            )}
          </Box>
        )}
    </Container>
  );
};

export default MetricsEditView;
