// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
import { nonReactSetUser } from "./UIStore";

const firebaseConfig = {
  apiKey: "AIzaSyAtzIaIhaw4Z4xJKx4Yueq_GFViGyagiOQ",
  authDomain: "noisey-liquids.firebaseapp.com",
  projectId: "noisey-liquids",
  storageBucket: "noisey-liquids.appspot.com",
  messagingSenderId: "203058084267",
  appId: "1:203058084267:web:090286d50d378995febaee",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

export const functions = getFunctions(app);

export const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then((registration) => {
      // messaging.useServiceWorker(registration);
      // request notification permission and get token
      console.log("Registration successful, scope is:", registration.scope);
      //TODO: ask For Permission To Receive Notifications
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

auth.onAuthStateChanged((newUser) => {
  nonReactSetUser(newUser);
});

if (process.env.NODE_ENV === "development") {
  //   connectAuthEmulator(auth, "http://localhost:9099");
  //   connectFirestoreEmulator(firestore, "localhost", 8080);
  //   connectFunctionsEmulator(functions, "localhost", 5001);
}
