import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box/Box";
import { firestore } from "../../utils/firebase";
import {
  doc,
  where,
  collection,
  collectionGroup,
  query,
  onSnapshot,
} from "firebase/firestore";
import { Dashboard } from "../../types/Dashboard";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import DashboardTable from "./DashboardTable";

// Icons
import AddIcon from "@mui/icons-material/Add";
import { NewDashboardDialog } from "./NewDashboardDialog";
import { EditSettingsDialog } from "./EditSettingsDialog";
import { useUIStore } from "utils/UIStore";

export interface DashboardsViewProps {}

export const DashboardsView = () => {
  const user = useUIStore((state) => state.user);
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [editSettingsDashboardID, setEditSettingsDashboardID] = useState<
    string | undefined
  >(undefined);
  const [canCreate, setCanCreate] = useState(false);
  const [userDashboards, setUserDashboards] = useState<Dashboard[]>([]);
  const [sharedWithDashboards, setSharedWithDashboards] = useState<Dashboard[]>(
    []
  );

  useEffect(() => {
    const fetchMyDashboards = async () => {
      if (user) {
        const q = query(collection(firestore, "users", user.uid, "dashboards"));

        onSnapshot(q, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: Dashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as Dashboard;
          });
          setUserDashboards(dashboards);
        });
      }
    };

    const fetchSharedDashboards = async () => {
      if (user) {
        const sharedWithQuery = query(
          collectionGroup(firestore, "dashboards"),
          where("sharedUsers", "array-contains", user.uid)
        );
        onSnapshot(sharedWithQuery, (querySnapshot) => {
          const docs = querySnapshot.docs;
          const dashboards: Dashboard[] = docs.map((d) => {
            return { id: d.id, ...d.data() } as Dashboard;
          });
          setSharedWithDashboards(dashboards);
        });
      }
    };

    const fetchCanCreate = async () => {
      if (user) {
        const q = doc(firestore, "users", user.uid, "private_data", "public");

        onSnapshot(q, (querySnapshot) => {
          const doc = querySnapshot;
          const data = doc.data();
          const membershipLevel: number = (data as any).membershipLevel;
          setCanCreate(!!membershipLevel && membershipLevel >= 100);
        });
      }
    };

    fetchMyDashboards();
    fetchSharedDashboards();
    fetchCanCreate();
  }, [user]);

  return (
    <Box style={{ padding: "2em" }}>
      {newDialogOpen && (
        <NewDashboardDialog onClose={() => setNewDialogOpen(false)} />
      )}
      {editSettingsDashboardID && (
        <EditSettingsDialog
          onClose={() => setEditSettingsDashboardID(undefined)}
          dashboardID={editSettingsDashboardID}
        />
      )}
      <Stack gap={2}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5"> My Dashboards</Typography>
          {canCreate && (
            <Tooltip title="New Dashboard">
              <IconButton onClick={() => setNewDialogOpen(true)}>
                <AddIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <DashboardTable
          rows={userDashboards}
          showEdit
          showSettings
          onEditClickHandler={setEditSettingsDashboardID}
        />
        <Divider />
        <Typography variant="h5"> Shared With Me</Typography>
        <DashboardTable rows={sharedWithDashboards} />
      </Stack>
    </Box>
  );
};
