import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import router from "./routes/Router";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { registerNotificationHandler } from "utils/notifications";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AuthProvider } from "components/AuthProvider";
import { CustomThemeProvider } from "components/theme/CustomThemeProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

registerNotificationHandler();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

root.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </LocalizationProvider>
    </CustomThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
