import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/system/Box/Box";
import { groupDataBySeason } from "./utils";
import { SearchTable } from "./SearchTable";

const recursiveProcessing = async (
  externalEndPoint: string,
  params: Record<string, string>
) => {
  const initialResponse = await fetch(
    externalEndPoint + "?" + new URLSearchParams(params)
  );
  const data: any[] = [];
  if (initialResponse.status === 200) {
    const responseData = await initialResponse.json();
    data.push(...responseData.result);
    if (responseData.remaining === 1 || responseData.remaining === "1") {
      const tempResults: any[] = await recursiveProcessing(externalEndPoint, {
        ...params,
        cursor: responseData.cursor,
      });

      data.push(...tempResults);
    }
  }
  return data;
};

export const SearchView = () => {
  const [datas, setDatas] = useState<any>();

  const [groupedData, setGroupedData] = useState<any>();
  const [address, setAddress] = useState<string>();
  useEffect(() => {
    const getDatas = async () => {
      if (address && address.length === 42) {
        const response1 = await recursiveProcessing(
          `https://api.x.immutable.com/v1/assets`,
          {
            user: address,
            collection: "0x738403f9034616b57e89dde4060979a9bba714cd",
            page_size: "2000",
          }
        );
        const response2 = await recursiveProcessing(
          `https://api.x.immutable.com/v1/assets`,
          {
            user: address,
            collection: "0x8c7b3bbd3f0918fa3d5a24a625cc688240d0b872",
            page_size: "2000",
          }
        );
        const response3 = await recursiveProcessing(
          `https://api.x.immutable.com/v1/assets`,
          {
            user: address,
            collection: "0x8e81ad255486d67af698fd88dd9367f6d2759b5b",
            page_size: "2000",
          }
        );
        setDatas([...response1, ...response2, ...response3]);
        setGroupedData(
          groupDataBySeason([...response1, ...response2, ...response3])
        );
      }
    };
    getDatas();
  }, [address]);
  console.log(groupedData);
  return (
    <>
      <Box padding={4}>
        <TextField
          label="IMX Address"
          type="text"
          variant="outlined"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Box>

      {datas && (
        <SearchTable
          data={datas
            .map((row: any) => ({
              ...row.metadata,
              collection: row.collection.name,
            }))
            .sort((a: any, b: any) =>
              +a.serialNumber < +b.serialNumber ? -1 : 1
            )}
        />
      )}
    </>
  );
};
