import * as React from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
interface DataGridProps {
  data: any[];
}

export const SearchTable: React.FC<DataGridProps> = ({ data }) => {
  const tableRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tableRef.current) {
      const table = new Tabulator(tableRef.current, {
        data,
        layout: "fitColumns",
        columns: [
          {
            title: "Season",
            field: "season",
            formatter: (cell, formatterParams) =>
              cell.getRow().getData().season ?? "",
          },
          {
            title: "",
            field: "image_url",
            formatter: "image",
            formatterParams: {
              height: "50px",
              width: "50px",
            },
          },
          { title: "Name", field: "name" },
          {
            title: "Type",
            field: "type",
            formatter: (cell, formatterParams) =>
              cell.getRow().getData().type ?? "",
          },
          {
            title: "Rarity",
            field: "rarity",
            formatter: (cell, formatterParams) =>
              cell.getRow().getData().rarity ?? "",
          },
          {
            title: "Serial Number",
            field: "serialNumber",
            formatter: (cell, formatterParams) =>
              cell.getRow().getData().serialNumber ?? "",
          },
          {
            title: "Collection",
            field: "collection",
            formatter: (cell, formatterParams) =>
              cell.getRow().getData().name ?? "",
          },
        ],
        groupBy: ["season", "name"],
        groupHeader: (value, count, data, group) => {
          return `${
            group.getField() === "season" ? "Season" : "Name"
          }: ${value} (${count})`;
        },
        groupStartOpen: false,
        layoutColumnsOnNewData: true,
      });

      return () => {
        table.destroy();
      };
    }
  }, [data]);

  return <div ref={tableRef} />;
};
