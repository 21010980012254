import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Visual } from "types/Dashboard";

//Icons
import ClearIcon from "@mui/icons-material/Clear";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface DashboardEditorButtonsProps {
  setLiveLayout: React.Dispatch<React.SetStateAction<Visual[]>>;
  liveLayout: Visual[];
  viz: Visual;
  setEditVisual: React.Dispatch<React.SetStateAction<Visual | undefined>>;
}

export const DashboardEditorButtons = ({
  setLiveLayout,
  liveLayout,
  viz,
  setEditVisual,
}: DashboardEditorButtonsProps) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        position: "fixed",
        top: 0,
        left: 10,
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius:20
      }}
    >
      <Tooltip title="Delete">
        <IconButton
          onClick={() =>
            setLiveLayout(liveLayout.filter((x) => x.id !== viz.id))
          }
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={`ID:${viz.id}`}>
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Settings">
        <IconButton onClick={() => setEditVisual(viz)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
