import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";

export type Review = {
  comment: string;
  rating: number;
  uuid: string;
};

export type ChatItem = {
  uuid: string;
  prompt: string;
  rawQuery: string;
  mappedQuery: string;
  when: Timestamp;
};

export type ReviewTableProps = {
  reviews: Review[];
  chatItems: ChatItem[];
};

export const ReviewTable: React.FC<ReviewTableProps> = ({
  reviews,
  chatItems,
}) => {
  const joinedData = reviews
    .map((review) => {
      const chatItem = chatItems.find((item) => item.uuid === review.uuid);
      return { ...review, ...chatItem };
    })
    .sort((a, b) => b.rating - a.rating);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="review table">
        <TableHead>
          <TableRow>
            <TableCell>UUID</TableCell>
            <TableCell>When</TableCell>
            <TableCell align="right">Comment</TableCell>
            <TableCell align="right">Rating</TableCell>
            <TableCell align="right">Prompt</TableCell>
            <TableCell align="right">Query</TableCell>
            <TableCell align="right">Mapped Query</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {joinedData.map((row) => (
            <TableRow key={row.uuid}>
              <TableCell component="th" scope="row">
                {row.uuid}
              </TableCell>
              <TableCell align="right">{row?.when?.toDate().toDateString()}</TableCell>
              <TableCell align="right">{row.comment}</TableCell>
              <TableCell align="right">{row.rating}</TableCell>
              <TableCell align="right">{row.prompt}</TableCell>
              <TableCell align="right" style={{ whiteSpace: "pre-wrap" }}>
                {row.rawQuery}
              </TableCell>
              <TableCell align="right" style={{ whiteSpace: "pre-wrap" }}>
                {row.mappedQuery}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
