import React from "react";
import { applyFilters, getValidFilters, useFactStore } from "../DataStore";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { groupyBySum } from "utils/dataManipulations";

export type StoreColumnChartProps = {
  id: string;
  metricID: number | string | null;
  yKey: string | null; // explicitly define the type as an array of strings
  color: string | null;
  xKey: string | null;
  topN: number | null;
};

export const StoreBarChart = ({
  id,
  metricID,
  yKey,
  color,
  xKey,
  topN,
}: StoreColumnChartProps) => {
  const factSet = useFactStore((state) => state.factSet);
  const filters = useFactStore((state) => state.filters);
  if (!metricID || !xKey) {
    new Error("Missing Keys");
  }
  const data = applyFilters(
    getValidFilters(filters, id),
    factSet[metricID ?? ""]
  );

  const grouped = groupyBySum(data, xKey ?? "", yKey ?? "")
    .sort((a, b) => (+a[yKey ?? ""] > +b[yKey ?? ""] ? -1 : 1))
    .slice(0, topN ?? 256);

  const options: ApexOptions = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: grouped.map((dataItem) =>
        typeof dataItem[xKey ?? ""] === "number"
          ? Math.round(dataItem[xKey ?? ""] as number)
          : `${dataItem[xKey ?? ""]}`
      ),
    },
    // yaxis: {
    //   labels: {
    //     formatter: (value: number) => typeof value === 'number' ?  value.toFixed(2): value,
    //   },
    // },
    colors: [color],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 0,
    //       borderColor: "rgba(0,0,0,0)",
    //       borderWidth: 0,
    //       label: {
    //         borderColor: "rgba(0,0,0,0)",
    //         style: {
    //           color: "#000080",
    //           background: "rgba(0,0,0,0)",
    //           fontFamily: "monospace",
    //         },
    //         text: "Metric.Club",
    //       },
    //     },
    //   ],
    // },
  };

  const series = [
    {
      name: yKey ?? "",
      data: grouped.map(
        (dataItem: any) => Math.round(+dataItem[yKey ?? ""] * 1_000) / 1_000
      ), // use yKeys as an index to access the properties in dataItem
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};
