import React from "react";

import { WidthProvider, Responsive, Layout, Layouts } from "react-grid-layout";
import { DashboardConfig } from "types/Dashboard";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export type DashboardProps = {
  config: DashboardConfig;
  children?: React.ReactNode;
  onDrop?: (layout: Layout[], item: Layout, e: DragEvent) => void;
  onLayoutChange?: (currentLayout: Layout[], allLayouts: Layouts) => void;
  onResizeStop?: (currentLayout: Layout[]) => void;
};

export const Dashboard = ({
  children,
  config,
  onDrop,
  onLayoutChange,
  onResizeStop,
}: DashboardProps) => {
  // State
  // Vars
  // Logic

  return (
    <ResponsiveReactGridLayout
      className="layout"
      isResizable={true}
      measureBeforeMount
      cols={config.cols as any}
      rowHeight={60}
      isBounded
      style={{
        backgroundColor: config.backgroundColor ?? "#FFFFFF",
        minHeight:200,
      }}
      onDrop={onDrop}
      isDroppable
      onLayoutChange={onLayoutChange}
      onResizeStop={onResizeStop}
    >
      {children}
    </ResponsiveReactGridLayout>
  );
};
