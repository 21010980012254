import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { DemoDashboard } from "../views/DemoDashboard";
import Root from "./Root";
import { DashboardsView } from "views/Dashboards/DashboardsView";
import { MetricsView } from "views/Metrics/MetricsView";
import { SettingsView } from "views/Settings/SettingsView";
import { DashboardViewer } from "views/Dashboards/DashboardViewer";
import { MetricsEditView } from "views/Metrics/MetricsEditView";
import { DataLoaderAdmin } from "views/Dataloader/DataLoaderAdmin";
import { SearchView } from "views/Search/Search";
import { MainView } from "views/Main";
import { NotificationsView } from "views/Notifications/NotificationsView";
import { DashboardEditor } from "views/Dashboards/EditView/DashboardEditor";
import { MetricsAdminView } from "views/Metrics/MetricsAdminView";
import { QnAView } from "views/QnA/QnAView";
import { QnAManager } from "views/QnA/QnAManager";
import { QnAFeedbackViewer } from "views/QnA/QnAFeedbackViewer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,

    // errorElement: <ErrorView />,
    children: [
      {
        path: "/",
        element: <MainView />,
      },
      {
        path: "demo",

        element: <DemoDashboard />,
      },
      {
        path: "dashboards",

        element: <DashboardsView />,
      },
      {
        path: "dashboards/edit/:dashboardID",
        element: <DashboardEditor />,
      },
      {
        path: "dashboards/:dashboardID",
        element: <DashboardViewer />,
      },
      {
        path: "metrics",

        element: <MetricsView />,
      },
      {
        path: "metrics/:metricID",

        element: <MetricsEditView />,
      },
      {
        path: "metricsAdmin",

        element: <MetricsAdminView />,
      },
      {
        path: "dataloaderAdmin",
        element: <DataLoaderAdmin />,
      },
      {
        path: "dataloaderAdmin/:loaderID",
        element: <DataLoaderAdmin />,
      },
      {
        path: "settings",

        element: <SettingsView />,
      },
      {
        path: "notifications",

        element: <NotificationsView />,
      },
      {
        path: "search",

        element: <SearchView />,
      },
      {
        path: "qna",

        element: <QnAView />,
      },
      {
        path: "qnaManager",

        element: <QnAManager />,
      },
      {
        path: "qnaFeedback",

        element: <QnAFeedbackViewer />,
      },
    ],
  },
]);

export default router;
